<template>
  <dialogModule
    top="3"
    width="90%"
    title="退货"
    v-if="salesVisible"
    :closeOnPressEscape="false"
    :dialogVisible="salesVisible"
    @close="close">
    <span slot="close" class="el-icon-close close-icon" @click="close"></span>
    <div slot="content" class="return-box">
      <div style="position: absolute; left: 30%; top: -30px; color: red; font-size: 16px; font-weight: bold">
        <!-- 提示：<span>{{ otherInfo.isWeek === 'Y' ? '当前7天内，原价退' : '当前超过7天，默认现价退' }}</span> -->
        <span>默认按现价退，有特殊情况时可以改价审批</span>
      </div>
      <section
        class="row"
        v-loading="fetchLoading"
        element-loading-text="请稍后，全力加载中..."
        element-loading-spinner="el-icon-loading">
        <div class="return-table">
          <grid-manager :option="gridOption"></grid-manager>
        </div>
        <div class="info-right">
          <div class="active-list" v-if="activeList.length">
            <div class="active-info" v-for="(item, idx) in activeList" :key="idx">
              <p>
                <span>{{ item.typeDesc }}</span>
                <i>{{ item.activityName }}</i>
              </p>
              <div class="row align-center space-between active-goods">
                <div class="row">
                  <div class="goods-relative" v-for="(goods, a) in item.list.slice(0, 3)" :key="a">
                    <el-image
                      v-if="goods.imageList"
                      class="goods-image"
                      :src="goods.imageList[0]"
                      :preview-src-list="goods.imageList"></el-image>
                    <div v-else class="goods-image row space-center align-center">暂无图片</div>
                    <span>{{ goods.goodsNo }}</span>
                    <div class="active-num">{{ goods.number }}件</div>
                    <div
                      :class="['active-type', goods.isAddBuy ? 'add-buy' : '', goods.isGift ? 'gift' : '', 'activity']">
                      {{ goods.isAddBuy ? '加购' : goods.isGift ? '赠品' : '促销' }}
                    </div>
                  </div>
                </div>
                <div class="cursor" @click="showAll(item)">
                  共{{ item.list.length }}款
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
              <div class="row align-center space-between" style="padding: 5px 10px;color:#666;" v-if="item.returnDiscount || item.returnPriceType">
                <div>部分退折率：<span style="color: #000;">{{item.returnDiscount}}</span></div>
                <div>部分退取价：<span style="color: #000;">{{item.returnPriceType}}</span></div>
              </div>
            </div>
          </div>
          <div class="return-info">
            <div class="return-tips">
              <!-- <p v-if="otherInfo.isWeek === 'Y'">当前7天内，原价退</p>
              <p v-else>当前超过7天，默认现价退</p> -->
            </div>
            <p class="return-title">退货信息</p>
            <div class="info-line">
              <span>原销售单：</span>
              <span>{{ otherInfo.innerSn }}</span>
            </div>
            <div class="info-line row align-center">
              <span>营业员：</span>
              <span>{{ otherInfo.userName }}</span>
              <span class="public-order" v-if="otherInfo.isPublic === 'Y'">公单</span>
            </div>
            <div class="info-line row align-center" v-if="otherInfo.crmPhone">
              <span>会员：</span>
              <span
                >{{ otherInfo.crmUserName }}&nbsp;&nbsp;&nbsp;{{
                  otherInfo.crmPhone.substr(0, 3) + '****' + otherInfo.crmPhone.substr(7)
                }}</span
              >
            </div>
            <div class="row align-center space-between old-money border-bottom">
              <span
                >原单最新金额：<i>￥{{ otherInfo.newOrderPrice || '0.00' }}</i></span
              >
              <span
                >销售件数：<i>{{ otherInfo.number || '0' }}</i></span
              >
            </div>
            <div class="row align-center space-between old-money">
              <span
                >默认退货总价：<i>￥{{ reduceResult.defaultTotalAmount || '0.00' }}</i></span
              >
              <span
                >退货件数：<i>{{ reduceResult.numTotal || '0' }}</i></span
              >
            </div>
            <div class="row align-center space-between old-money">
              <span
                >退款金额：<i>￥{{ reduceResult.realTotalAmount || '0.00' }}</i></span
              >
            </div>
            <div class="return-reason">
              <p><span>*</span>请描述退货原因：</p>
              <el-input type="textarea" placeholder="请输入" resize="none" rows="3" v-model="description"></el-input>
            </div>
          </div>
          <el-button class="return-btn" type="primary" :loading="fetchLoading" @click="submitBefore"
            >确认退货</el-button
          >
        </div>
      </section>
      <ActiveGoodsList
        v-if="activeInfoVisible"
        :activeObject="activeObject"
        @close="activeInfoVisible = false"></ActiveGoodsList>
      <ReturnChangePrice
        v-if="changePriceVisible"
        :approveList="approveList"
        :approveInfo="approveInfo"
        :tableData="tableData"
        :hrFlowTypeId="hrFlowTypeId"
        :reduceResult="reduceResult"
        :snNo="otherInfo.innerSn"
        @close="changePriceVisible = false"
        @sure="approveSubmit">
      </ReturnChangePrice>
    </div>
  </dialogModule>
</template>

<script>
import ReturnChangePrice from './ReturnChangePrice'
import dialogModule from '@/components/dialogModule.vue'
import ActiveGoodsList from '@/views/deptCollectMoney/components/ActiveGoodsList.vue'
import { returnSalesActivity, returnSalesSubmit, salesAfterOperate } from '@/libs/http/modules/posOrder'
export default {
  name: 'returnGoods',
  props: {
    invoicesItem: {
      type: Object,
      default: () => null
    }
  },
  components: {
    dialogModule,
    ActiveGoodsList,
    ReturnChangePrice
  },
  data() {
    return {
      salesVisible: true,
      fetchLoading: false,
      sell: require('@/assets/images/sell.png'),
      give: require('@/assets/images/give.png'),
      back: require('@/assets/images/return.png'),
      rite: require('@/assets/images/rite.png'),
      gridOption: {
        gridManagerName: 'return-goods-table',
        firstLoading: true,
        height: '85vh',
        isCombSorting: true,
        supportAutoOrder: false,
        supportCheckbox: true,
        supportMenu: false,
        supportConfig: true,
        disableAutoLoading: true,
        disableBorder: false,
        disableLine: true,
        checkboxConfig: {
          width: 50
        },
        columnData: [
          {
            key: 'operationType',
            text: '类型',
            align: 'center',
            width: '60px',
            template: () => {
              return `
								<div>
                  <img style="width:40px;height:40px;" v-if="row.operationType === '1'" :src="sell" />
                  <img style="width:40px;height:40px;" v-if="row.operationType === '2'" :src="give" />
                  <img style="width:40px;height:40px;" v-if="row.operationType === '4'" :src="back" />
                  <img style="width:40px;height:40px;" v-if="row.operationType === '3'" :src="rite" />
                  <img style="width:40px;height:40px;" v-if="row.operationType === '6'" :src="ned" />
								</div>
						    `
            }
          },
          {
            key: 'goodsInfo',
            text: '货品信息',
            align: 'center',
            width: '300px',
            template: () => {
              return `
								<div class="row" style="text-align:left;">
									<div style="width:102px;height:92px;margin-right:10px;position: relative;overflow: hidden;">
										<el-image v-if="row.imageList && row.imageList.length" style="width:102px;height:92px;border-radius:4px;" :preview-src-list="row.imageList" :src="row.imageList[0]"></el-image>
								    	<div v-else class="row align-center space-center" style="background: #eee;width:102px;height:92px;border-radius:4px;color: #999;">暂无图片</div>
								    	<div v-if="row.activityId || row.isExchange === 1 || row.operationType === '2'"
								    		style="position: absolute;left: 50px;top: 5px;width:70px;line-height: 20px;color:#fff;transform: rotate(45deg);text-align: center;font-size: 12px;"
											:style="row.isExchange === 1 ? 'background: linear-gradient(0deg, rgb(116,47,169), rgb(101,41,147), rgb(72,30,105))' : row.operationType === '2' ? 'background: linear-gradient(0deg, #208AEC, #02D7F0, #00C6F7)' : 'background: linear-gradient(-90deg, #EC4620, #F08702, #F7D400)'">
								    		<span>{{row.isExchange === 1 ? '加购' : row.operationType === '2' ? '赠品' : '促销'}}</span>
										</div>
								    </div>
								    <div class="column space-between" style="height:90px;width: calc(100% - 112px);">
										<div style="font-size:18px;color:#333;">{{row.goodsName}}</div>
										<div style="font-size:14px;color:#999;">货号：{{row.goodsNo}}</div>
										<div style="font-size:14px;color:#999;">条码：{{row.barCode}}</div>
								    </div>
								</div>
							`
            }
          },
          {
            key: 'attr',
            text: '颜色 / 尺码',
            align: 'center',
            width: '140px',
            template: () => {
              return `
								<div v-if="row.attr" class="row space-center align-center text-center" style="border:1px solid #DEEEF9;background:#fff;line-height:34px;border-radius:2px;">
									<p v-for="(item, index) in row.attr.slice(0,2)" :key="item.code">{{item.value}}
									  	<span v-if="index == 0" style="margin:0 3px;">,</span>
									</p>
							  	</div>
                    		`
            }
          },
          {
            key: 'realPrice',
            text: '原单卖价',
            align: 'center',
            width: '100px',
            template: () => {
              return `
								<div style="font-size: 16px;">￥{{row.realPrice}}</div>
						    `
            }
          },
          {
            key: 'number',
            text: '数量',
            align: 'center',
            template: () => {
              return `
								<div style="font-size: 16px;">{{row.number}}</div>
							`
            }
          },
          {
            key: 'totalPrice',
            text: '合计金额',
            align: 'center',
            width: '100px',
            template: () => {
              return `
								<div style="color:#F8475F;font-size: 18px;">￥{{row.totalPrice}}</div>
						    `
            }
          },
          {
            key: 'originPrice',
            text: '当前零售价',
            align: 'center',
            width: '100px',
            template: () => {
              return `
								<div style="font-size: 16px;">￥{{row.originPrice}}</div>
							`
            }
          },
          {
            key: 'fixedRetreatPrice',
            text: '默认退货价',
            align: 'center',
            width: '120px',
            template: () => {
              return `
                <div>
                    <div style="font-size: 16px;">￥{{row.fixedRetreatPrice || '0.00'}}</div>
                    <div v-if="row.isHasActivityDiscount === 'Y'" style="width:80px;height:20px;background:#ffa940;color:#fff;margin: auto;">部分退折率</div>
                </div>
							`
            }
          },
          {
            key: 'retreatPrice',
            text: '退货价',
            align: 'center',
            width: '120px',
            template: () => {
              return `
								<div>
								  	<span style="font-size: 16px;">￥</span>
								  	<input
								  	type="text"
								  	:disabled="row.isChangePrice !=='Y'"
								  	v-model="row.retreatPrice"
								  	@change="changePrice(row, index)"
								  	@input="row.retreatPrice=row.retreatPrice.replace(/^([0-9-]\\d*\\.?\\d{0,2})?.*$/,'$1')"
								  	style="width:80px;height:36px;line-height:36px;text-align:center;border:1px solid #eee;color:#333;outline:none;font-size:18px;">
								  	<div @click="recoverPrice(row, index)" v-if="row.isUpdatePrice === 'Y'" class="row space-between align-center" style="background: rgb(255, 144, 0);color: #fff;width: 80px;margin-left: 20px;font-size: 12px;padding: 0 10px;">
								  	    <span>已改价</span>
								  	    <span class="el-icon-close"></span>
                    </div>
								</div>
						    `
            }
          },
          {
            key: 'retreatNumber',
            text: '退货数量',
            align: 'center',
            width: '150px',
            template: () => {
              return `
								<div class="row align-center space-center">
									<div v-if="row.isChangeNumber ==='Y'" style="width:40px;height:40px;line-height:40px;text-align:center;font-size:18px;border:1px solid #eee;cursor:pointer;font-weight: bold;" @click="minusHandel(row, index)">
									  	<span class="el-icon-minus"></span>
									</div>
									<div v-else style="width:40px;height:40px;line-height:40px;text-align:center;font-size:18px;font-weight: bold;background: #eee;color:#ddd;">
										<span class="el-icon-minus"></span>
									</div>
									<input
									type="text"
								  	:disabled="row.isChangeNumber !=='Y'"
									v-model="row.retreatNumber"
									@change="blurHandel(row, index)"
									@input="row.retreatNumber=row.retreatNumber.replace(/^0|[^0-9]/g,'')"
									style="width:50px;height:40px;line-height:40px;text-align:center;border:1px solid #eee;border-left:none;color:#333;outline:none;font-size:18px;" />
									<div v-if="row.isChangeNumber ==='Y'" style="background:#067CF2;color:#fff;width:40px;height:40px;line-height:40px;text-align:center;font-size:18px;cursor:pointer;font-weight: bold;" @click="plusHandel(row, index)">
									  	<span class="el-icon-plus"></span>
									</div>
									<div v-else style="background:#eee;color:#ddd;width:40px;height:40px;line-height:40px;text-align:center;font-size:18px;font-weight: bold;">
									  	<span class="el-icon-plus"></span>
									</div>
							    </div>
							`
            }
          },
          {
            key: 'totalRetreatPrice',
            text: '退货金额',
            align: 'center',
            width: '120px',
            template: () => {
              return `
								<div style="color:#F8475F;font-size: 18px;">￥{{row.totalRetreatPrice}}</div>
						    `
            }
          }
        ],
        ajaxData: this.showGoodsTable,
        cellClick: this.cellClick,
        checkedAllAfter: this.checkedAllAfter
      },
      activeInfoVisible: false,
      activeObject: null,
      tableData: [],
      goodInfos: [],
      description: '',
      reduceResult: {},
      otherInfo: {},
      activeList: [],
      changePriceVisible: false,
      approveList: [],
      approveInfo: {},
      hrFlowTypeId: '',
      hasAlterPriceInfo: '',
      isAllowNegativeStocks: ''
    }
  },
  created() {
    let operateGoodInfo = {
      alterGoodsWay: '4'
    }
    this.operateList(operateGoodInfo)
    this.fetchActive()
  },
  methods: {
    close() {
      this.salesVisible = false
      this.$emit('close')
    },
    showGoodsTable() {
      return new Promise((resolve, reject) => {
        const tableData = {
          data: this.tableData
        }
        resolve(tableData)
      })
    },
    fetchActive() {
      returnSalesActivity({
        id: this.invoicesItem.id
      })
        .then(res => {
          if (res) {
            this.otherInfo = {
              isWeek: res.isWeek,
              number: res.number,
              innerSn: res.innerSn,
              isPublic: res.isPublic,
              crmPhone: res.crmPhone,
              userName: res.userName,
              crmUserName: res.crmUserName,
              newOrderPrice: res.newOrderPrice
            }
            res.activityOrderDetailPojoList.forEach(item => {
              this.$set(item, 'list', [])
              this.$set(item, 'isGoods', true)
              if (item.goodInfos && item.goodInfos.length) {
                item.list = item.list.concat(item.goodInfos)
              }
              if (item.addGoodInfos && item.addGoodInfos.length) {
                item.addGoodInfos.forEach(gave => {
                  this.$set(gave, 'isAddBuy', true)
                })
                item.list = item.list.concat(item.addGoodInfos)
              }
              if (item.giftGoodInfos && item.giftGoodInfos.length) {
                item.giftGoodInfos.forEach(gift => {
                  this.$set(gift, 'isGift', true)
                })
                item.list = item.list.concat(item.giftGoodInfos)
              }
            })
            this.activeList = res.activityOrderDetailPojoList
          }
        })
        .catch(error => {
          this.$message({ showClose: true, message: error, type: 'error', duration: 2000 })
        })
    },
    cellClick(rowData, rowIndex, colIndex) {
      if (colIndex !== 0) return
      if (rowData.isSelect === 'N') {
        if (rowData.activityId) {
          if (rowData.activityType.toString() === '2') {
            // this.$confirm('该货品参与了M件价N促销，将按活动退货价计算退款', '提示', {
            //   showCancelButton: false,
            //   closeOnPressEscape: false,
            //   closeOnClickModal: false,
            //   confirmButtonText: '知道了'
            // })
            //   .then(res => {
            //     let operateGoodInfo = {
            //       index: rowIndex,
            //       alterGoodsWay: '1',
            //       barCode: rowData.barCode
            //     }
            //     this.operateList(operateGoodInfo)
            //   })
            //   .catch(() => {
            //     this.setChecked()
            //   })

            let operateGoodInfo = {
              index: rowIndex,
              alterGoodsWay: '1',
              barCode: rowData.barCode
            }
            this.operateList(operateGoodInfo)
          } else {
            // this.$confirm('该货品参与了促销活动，退货需要将同促销的货品一并退货！确定要退货吗？', '提示')
            //   .then(res => {
            //     let operateGoodInfo = {
            //       index: rowIndex,
            //       alterGoodsWay: '1',
            //       barCode: rowData.barCode
            //     }
            //     this.operateList(operateGoodInfo)
            //   })
            //   .catch(() => {
            //     this.setChecked()
            //   })

            let operateGoodInfo = {
              index: rowIndex,
              alterGoodsWay: '1',
              barCode: rowData.barCode
            }
            this.operateList(operateGoodInfo)
          }
        } else {
          let operateGoodInfo = {
            index: rowIndex,
            alterGoodsWay: '1',
            barCode: rowData.barCode
          }
          this.operateList(operateGoodInfo)
        }
      } else {
        let operateGoodInfo = {
          index: rowIndex,
          alterGoodsWay: '2',
          barCode: rowData.barCode
        }
        this.operateList(operateGoodInfo)
      }
    },
    setChecked() {
      const checkList = this.tableData.filter(item => {
        return item.isSelect === 'Y'
      })
      GridManager.setCheckedData(this.gridOption.gridManagerName, checkList)
      GridManager.refreshGrid(this.gridOption.gridManagerName)
    },
    checkedAllAfter(checkedList, isChecked) {
      let operateGoodInfo = {
        isCheckAll: 'Y',
        alterGoodsWay: isChecked ? '1' : '2'
      }
      this.operateList(operateGoodInfo)
    },
    changePrice(row, idx) {
      // if (!row.retreatPrice || Number(row.retreatPrice) < 0) {
      //   GridManager.renderGrid(this.gridOption.gridManagerName)
      //   return this.$message({ showClose: true, message: `请输入大于0的数字`, type: 'error', duration: 2000 })
      // }

      // if (Number(row.retreatPrice) > Number(row.realPrice)) {
      //   GridManager.renderGrid(this.gridOption.gridManagerName)
      //   return this.$message({ showClose: true, message: `输入的金额不能大于原单卖价`, type: 'error', duration: 2000 })
      // }
      let operateGoodInfo = {
        index: idx,
        alterGoodsWay: '3',
        barCode: row.barCode,
        price: row.retreatPrice
      }
      this.operateList(operateGoodInfo)
    },
    plusHandel(row, idx) {
      if (Number(row.retreatNumber) >= Number(row.number)) {
        GridManager.renderGrid(this.gridOption.gridManagerName)
        return this.$message({ showClose: true, message: `退货数量不能大于购买数量`, type: 'error', duration: 2000 })
      }
      let operateGoodInfo = {
        index: idx,
        barCode: row.barCode,
        number: ++row.retreatNumber,
        alterGoodsWay: '3'
      }
      this.operateList(operateGoodInfo)
    },
    minusHandel(row, idx) {
      if (Number(row.retreatNumber) <= 1) {
        GridManager.renderGrid(this.gridOption.gridManagerName)
        return this.$message({ showClose: true, message: '退货数量不能小于1', type: 'error', duration: 2000 })
      }
      let operateGoodInfo = {
        index: idx,
        barCode: row.barCode,
        number: --row.retreatNumber,
        alterGoodsWay: '3'
      }
      this.operateList(operateGoodInfo)
    },
    blurHandel(row, idx) {
      if (row.retreatNumber % 1 !== 0 || !row.retreatNumber || Number(row.retreatNumber) > Number(row.number)) {
        GridManager.renderGrid(this.gridOption.gridManagerName)
        return this.$message({
          showClose: true,
          message: `退货数量不能为0且不能大于购买数量`,
          type: 'error',
          duration: 2000
        })
      }
      let operateGoodInfo = {
        index: idx,
        barCode: row.barCode,
        number: row.retreatNumber,
        alterGoodsWay: '3'
      }
      this.operateList(operateGoodInfo)
    },
    recoverPrice(row, idx) {
      this.$confirm('确认要取消改价', '提示')
        .then(res => {
          let operateGoodInfo = {
            index: idx,
            alterGoodsWay: '1',
            barCode: row.barCode,
            isNoUpdatePrice: 'Y'
          }
          this.operateList(operateGoodInfo)
        })
        .catch(() => {})
    },
    operateList(operateGoodInfo) {
      this.fetchLoading = true
      let reqData = {
        operationOrderType: '1',
        orderId: this.invoicesItem.id
      }
      if (operateGoodInfo) {
        reqData['operateGoodInfo'] = operateGoodInfo
      }
      if (this.goodInfos && this.goodInfos.length) {
        reqData['goodInfos'] = this.goodInfos
      }
      salesAfterOperate(reqData)
        .then(res => {
          this.tableData = res.goodInfos
          this.reduceResult = res.reduceResult
          this.hasAlterPriceInfo = res.hasAlterPriceInfo
          if (!this.hrFlowTypeId) {
            this.hrFlowTypeId = res.hrFlowTypeId
          }
          if (!this.isAllowNegativeStocks) {
            this.isAllowNegativeStocks = res.isAllowNegativeStocks
          }
          if (res && res.message && res.message !== '') {
            this.$message.error(res.message)
          }
          this.goodInfos = JSON.parse(JSON.stringify(res.goodInfos))
          this.setChecked()
          this.fetchLoading = false
        })
        .catch(error => {
          GridManager.renderGrid(this.gridOption.gridManagerName)
          this.setChecked()
          this.fetchLoading = false
          this.$message({ showClose: true, message: error, type: 'error', duration: 2000 })
        })
    },
    showAll(item) {
      this.activeObject = item
      this.activeInfoVisible = true
    },
    submitBefore() {
      this.approveList = []
      let checkList = GridManager.getCheckedData(this.gridOption.gridManagerName)
      if (!checkList.length) {
        return this.$message({ showClose: true, message: '请选择要退货的货品', type: 'error', duration: 2000 })
      }
      if (!this.description) {
        return this.$message({ showClose: true, message: '请填写退货原因', type: 'error', duration: 2000 })
      }
      this.approveList = this.tableData.filter(item => {
        return item.isUpdatePrice === 'Y' && item.isSelect === 'Y'
      })
      if (this.approveList.length) {
        let tipList = this.approveList.map(item => {
          return item.goodsNo + '/' + item.goodsName
        })
        const confirmText = ['以下货品改价金额超出了可改范围，请点击【确定】提交改价申请！', ...tipList]
        const newDatas = []
        const h = this.$createElement
        for (const i in confirmText) {
          newDatas.push(h('p', null, confirmText[i]))
        }
        this.$confirm('改价提醒', {
          title: '改价提醒',
          message: h('div', null, newDatas)
        })
          .then(() => {
            this.approveInfo = {
              ...this.otherInfo,
              remark: this.description,
              orderId: this.invoicesItem.id,
              orderTypeId: this.invoicesItem.orderTypeId
            }
            this.changePriceVisible = true
          })
          .catch(() => {})
      } else {
        if (this.otherInfo.isPublic === 'Y' || this.otherInfo.crmPhone) {
          let str = '请确保退货信息无误，确定后退货生效！'
          if (this.otherInfo.isPublic === 'Y') {
            str = str + '公单销售会按原来的份额比例扣减业绩 '
          }
          if (this.otherInfo.crmPhone) {
            str = str + '会员销售会减少会员积分'
          }
          this.$confirm(str, '提示')
            .then(() => {
              this.sureSubmit()
            })
            .catch(() => {})
        } else {
          this.sureSubmit()
        }
      }
    },
    sureSubmit() {
      this.fetchLoading = true
      let reqData = {
        remark: this.description,
        goodInfos: this.tableData,
        orderId: this.invoicesItem.id,
        reduceResult: this.reduceResult,
        hasAlterPriceInfo: this.hasAlterPriceInfo,
        orderTypeId: this.invoicesItem.orderTypeId
      }
      const cashierItem = JSON.parse(this.$cache.local.get('cashierDeskItem'))
      if (cashierItem) {
        reqData['terminalId'] = cashierItem.id
      }
      returnSalesSubmit(reqData)
        .then(res => {
          this.salesVisible = false
          this.$emit('refresh')
          this.fetchLoading = false
        })
        .catch(error => {
          this.fetchLoading = false
          this.$message({ showClose: true, message: error, type: 'error', duration: 2000 })
        })
    },
    approveSubmit() {
      this.salesVisible = false
      this.$emit('refresh')
    }
  }
}
</script>

<style lang="scss" scoped>
.close-icon {
  color: #999;
  padding: 10px;
  margin-right: -10px;
  cursor: pointer;
}
.return-box {
  padding: 10px;
  position: relative;
  .return-table {
    width: calc(100% - 410px);
  }
  .info-right {
    width: 400px;
    margin-left: 10px;
    .active-list {
      background: #f5f5f5;
      padding: 10px;
      border-radius: 6px;
      margin-bottom: 15px;
      .active-info {
        background: #fff;
        margin-bottom: 10px;
        border-radius: 4px;
        &:last-child {
          margin-bottom: 0;
        }
        p {
          border-bottom: 1px solid #eee;
          line-height: 32px;
          span {
            padding: 0 6px;
            background: rgb(255, 144, 0);
            color: #fff;
            border-radius: 4px;
            margin: 0 10px;
          }
          i {
            font-style: normal;
          }
        }
        .active-goods {
          padding: 8px 10px 2px;
          .goods-relative {
            width: 86px;
            margin-right: 10px;
            position: relative;
            overflow: hidden;
            &:last-child {
              margin-right: 0;
            }
            .goods-image {
              width: 86px;
              height: 80px;
              border-radius: 4px;
              float: left;
              background: #eee;
              color: #999;
            }
            .active-num {
              position: absolute;
              left: 0;
              bottom: 19px;
              font-size: 10px;
              color: #fff;
              background: #333;
              padding: 2px 6px;
              border-radius: 0 4px 0 4px;
            }
            .active-type {
              position: absolute;
              right: -38px;
              top: 3px;
              width: 100px;
              height: 18px;
              line-height: 18px;
              font-size: 12px;
              text-align: center;
              color: #fff;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
    .return-info {
      color: #000;
      padding: 10px;
      border-radius: 6px;
      margin-bottom: 20px;
      position: relative;
      background: rgb(255, 247, 230);
      box-shadow: 0 0 6px 0 rgba(4, 51, 97, 0.29);
      .return-tips {
        position: absolute;
        right: 5px;
        top: 6px;
        font-size: 16px;
        color: red;
        font-weight: bold;
      }
      .return-title {
        font-size: 18px;
        margin-bottom: 10px;
      }
      .info-line {
        line-height: 22px;
        color: #666;
        .public-order {
          margin-left: 30px;
          border-radius: 4px;
          font-size: 12px;
          line-height: 16px;
          padding: 0 8px;
          color: rgb(250, 140, 22);
          background: rgb(255, 231, 186);
          border: 1px solid rgb(250, 140, 22);
        }
      }
      .old-money {
        color: #666;
        font-size: 15px;
        margin-top: 10px;
        padding-bottom: 8px;
        i {
          font-style: normal;
          color: red;
          font-size: 18px;
        }
      }
      .border-bottom {
        border-bottom: 1px solid #ddd;
      }
      .return-reason {
        p {
          margin-top: 10px;
          margin-bottom: 5px;
          font-size: 15px;
          color: #666;
          span {
            color: red;
          }
        }
      }
    }
    .return-btn {
      width: 100%;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
.table-style {
  border: 1px solid #eee;
  .change-img {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    color: #999;
    background: #eee;
    float: left;
  }
  .change-info {
    text-align: left;
    margin-left: 10px;
    height: 80px;
    p {
      font-size: 16px;
      color: #000;
    }
  }
  .color-size {
    border: 1px solid #eee;
    padding: 5px 0;
  }
  .red-text {
    color: red;
  }
  .change-goods {
    color: #fff;
    cursor: pointer;
    background: #0b83f3;
    padding: 5px 10px;
    border-radius: 2px;
  }
  .table-table {
    width: 70%;
    margin-left: 15%;
  }
}
.activity {
  background: linear-gradient(-90deg, #ec4620, #f08702, #f7d400) !important;
}
.gift {
  background: linear-gradient(0deg, #208aec, #02d7f0, #00c6f7) !important;
}
.add-buy {
  background: linear-gradient(0deg, rgb(116, 47, 169), rgb(101, 41, 147), rgb(72, 30, 105)) !important;
}
</style>
