<template>
  <el-dialog
    top="25vh"
    width="600px"
    title="整单退货"
    append-to-body
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    v-loading="submitLoading"
    element-loading-text="请稍后，全力加载中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.2)"
    @close="close">
    <section>
      <p class="tips-text" v-if="invoicesItem.marketingQuality == 'Y'">
        本单的支付方式为组合支付并且包含会员余额支付，只允许按原单卖价整单退货，需退现金
        <span>{{ invoicesItem.otherAmount }}</span
        >元，会员余额支付的 <span>{{ invoicesItem.crmAmount }}</span
        >元将退回会员余额。
      </p>
      <p class="tips-text" v-if="invoicesItem.marketingQuality == 'N'">
        本单的支付方式为组合支付并且包含会员余额支付，只允许按原单卖价整单退货，需退现金
        <span>{{ $amount.add(Number(invoicesItem.otherAmount), Number(invoicesItem.crmAmount)) }}</span
        >元
      </p>
      <div class="return-reason">
        <p>
          <span>*</span>
          请描述退货原因：
        </p>
        <el-input type="textarea" resize="none" rows="5" placeholder="请输入" v-model="description"></el-input>
      </div>
      <div class="action-btn row space-end">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="sureSubmit">确 认</el-button>
      </div>
    </section>
  </el-dialog>
</template>

<script>
import { submitAllRetreatOrder } from '@/libs/http/modules/posOrder'
export default {
  name: 'AllOrdingReturn',
  props: {
    invoicesItem: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      description: '',
      dialogVisible: true,
      submitLoading: false
    }
  },
  methods: {
    close() {
      this.dialogVisible = false
      this.$emit('close')
    },
    sureSubmit() {
      if (!this.description) {
        return this.$message({ showClose: true, message: '请填写退货原因', type: 'error', duration: 2000 })
      }
      this.submitLoading = true
      let reqData = {
        remark: this.description,
        orderId: this.invoicesItem.id,
        orderTypeId: this.invoicesItem.orderTypeId
      }
      const cashierItem = JSON.parse(this.$cache.local.get('cashierDeskItem'))
      if (cashierItem) {
        reqData['terminalId'] = cashierItem.id
      }
      submitAllRetreatOrder(reqData)
        .then(res => {
          this.dialogVisible = false
          this.$emit('refresh')
          this.submitLoading = false
        })
        .catch(error => {
          this.submitLoading = false
          this.$message({ showClose: true, message: error, type: 'error', duration: 2000 })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.tips-text {
  margin-top: -30px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 26px;
  span {
    color: red;
  }
}
.return-reason {
  p {
    margin-bottom: 5px;
    span {
      color: red;
    }
  }
}
.action-btn {
  margin-top: 40px;
}
</style>
