<template>
  <section class="flow">
    <div class="flow-title row align-center">
      <div class="line"></div>
      <span>审批流程</span>
    </div>
    <div class="flow-content">
      <div class="flow-step row" v-for="(flow, i) in flowList" :key="i">
        <div class="flow-left">
          <div class="row align-center space-center flow-out">
            <div class="flow-inner"></div>
          </div>
          <div class="flow-line"></div>
        </div>
        <div class="flow-right">
          <div class="row align-center detail-titel">
            <p>
              {{ flow.stepTitle ? flow.stepTitle : stateOption[flow.state] }}
            </p>
            <span>{{ reviewTypeOption[flow.reviewType] }}</span>
          </div>
          <div
            class="row align-center detail-user"
            v-for="(user, idx) in flow.users"
            :key="idx"
          >
            <div class="name-avat">
              <div class="row align-center">
                <div class="avator">
                  <el-image
                    v-if="user.userAvatar"
                    :src="user.userAvatar"
                    class="avat-img"
                  ></el-image>
                  <div v-else class="avat-null row align-center space-center">
                    {{ user.userName.substr(0, 1) }}
                  </div>
                  <div
                    v-if="user.state == 2"
                    class="iconfont icon-chenggong"
                  ></div>
                  <div
                    v-if="user.state == 3"
                    class="iconfont icon-shibai"
                  ></div>
                </div>
                <div>{{ user.userName }}</div>
              </div>
              <div class="step-info" v-if="user.description">
                {{ user.description }}
              </div>
            </div>
            <div class="step-info" style="margin-left: 40px">
              {{
                user.state == -1
                  ? "已撤回"
                  : user.state == 1
                  ? "未处理"
                  : user.state == 2
                  ? "已同意"
                  : user.state == 3
                  ? "已拒绝"
                  : user.state == 4
                  ? "已执行"
                  : ""
              }}·{{ user.reviewTime }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FlowDetail",
  props: ["instanceFlowListResp"],
  data() {
    return {
      curStep: "",
      flowList: [],
      reviewTypeOption: {
        0: "全签",
        1: "或签",
      },
      stateOption: {
        0: "未审核",
        1: "审核通过",
        2: "审核拒绝",
      },
    };
  },
  created() {
    this.flowList = this.instanceFlowListResp.flows;
    this.curStep = this.instanceFlowListResp.step;
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.line {
  width: 4px;
  height: 24px;
  background: #067cf2;
  margin-right: 20px;
}
.flow {
  background: #fff;
  width: 100%;
  .flow-title {
    color: #656d78;
    font-family: "font-Medium";
    line-height: 46px;
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .flow-content {
    padding: 0 25px;
    font-family: "font-Light";
    .flow-step {
      .flow-left {
        width: 40px;
        .flow-out {
          width: 15px;
          height: 15px;
          background: #43d268;
          border-radius: 50%;
          margin-top: 3px;
          .flow-inner {
            width: 9px;
            height: 9px;
            background: #fff;
            border-radius: 50%;
          }
        }
        .flow-line {
          width: 1px;
          background: #ccc;
          margin-left: 7px;
          height: calc(100% - 20px);
        }
      }
      .flow-right {
        flex: 1;
        .detail-titel {
          font-family: "font-Regular";
          margin-bottom: 20px;
          p {
            font-size: 18px;
          }
          span {
            width: 40px;
            text-align: center;
            border: 1px solid #43d268;
            font-size: 12px;
            border-radius: 2px;
            margin-left: 10px;
          }
        }
        .detail-user {
          margin-bottom: 20px;
          .name-avat {
            .avator {
              position: relative;
              width: 50px;
              height: 50px;
              border-radius: 50%;
              margin-right: 10px;
              margin-bottom: 5px;
              .avat-img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                float: left;
              }
              .avat-null {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background: #067cf2;
                color: #fff;
                font-size: 22px;
              }
            }
            .icon-chenggong,
            .icon-shibai {
              position: absolute;
              bottom: 5px;
              right: 5px;
              font-size: 15px;
              background: #fff;
              border-radius: 50%;
            }
            .icon-chenggong {
              color: #43d268;
            }
            .icon-shibai {
              color: #f8475f;
            }
          }
        }
        .step-info {
          font-size: 14px;
          color: #999;
        }
      }
    }
  }
}
</style>