<template>
  <section class="row align-center tab-content">
    <div
      v-for="(item, index) in list"
      :key="item.id"
      :class="['tab-item', curIndex == item.id ? 'select-item' : '']"
      @click="changeTabs(item.id, index)"
    >
      <span>{{ item.name }}</span>
    </div>
  </section>
</template>

<script>
export default {
  name: "tabsMenu",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    curTab: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      curIndex: 0,
    };
  },
  created() {
    this.curIndex = this.list[0].id;
    // this.$emit("curItem", this.list[0].id);
  },
  watch: {
    curTab: {
      handler(newName, oldName) {
        this.curIndex = newName;
      },
      immediate: true,
    },
  },
  methods: {
    changeTabs(id, index) {
      this.curIndex = id;
      this.$emit("curItem", { id, index });
    },
  },
};
</script>

<style scoped lang="scss">
.tab-content {
  border-radius: 4px;
  background: #f9f9f9;
  border: 1px solid #067cf2;
  padding: 5px;
  .tab-item {
    font-family: "font-Light";
    cursor: pointer;
    color: #666;
    font-size: 18px;
    padding: 8px 25px;
  }
  .select-item {
    color: #fff;
    background: #067cf2;
    border-radius: 4px;
  }
}
</style>