<template>
  <el-dialog
    top="15vh"
    width="800px"
    append-to-body
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    @close="close">
    <div slot="title">
      寄件到客
      <span>{{ deptType === 'ZY' ? '（直营）' : deptType === 'JM' ? '（加盟）' : '' }}</span>
    </div>
    <section class="dialog-box">
      <div class="address-box">
        <div class="row align-center sn-row">
          <span>单据号</span>
          <p>{{snData.orderNo}}</p>
        </div>
        <div class="row address-row">
          <div class="row address-item">
            <div class="item-left" style="color: #0b83f3;">发出方</div>
            <div class="column space-between item-right">
              <div class="name">{{send.receiverName}}</div>
              <div class="phone">{{send.phone}}</div>
              <div class="address">{{send.province + send.city + send.area + send.address}}</div>
            </div>
          </div>
          <div class="row address-item">
            <div class="item-left" style="background: rgb(255,237,235);color: red;">收货方</div>
            <div class="column space-between item-right">
              <div class="name">{{addressObj.receiverName}}</div>
              <div class="phone">{{addressObj.phone}}</div>
              <div class="address">{{addressObj.province + addressObj.city + addressObj.area + addressObj.address}}</div>
            </div>
            <div class="change-address" @click="addressVisible = true">{{snData.expressNo ? '修改收货信息' : '填写收货信息'}}</div>
          </div>
        </div>
      </div>

      <div class="row align-center" style="margin-top: 20px;">
        <div style="width: 84px;text-align: right;margin-right: 5px;">
          <i style="color: red;margin-right: 5px;">*</i>
          <span>录入方式：</span>
        </div>
        <el-radio-group v-model="otherObj.orderType">
          <el-radio label="1" border>京东自动下单</el-radio>
          <el-radio v-if="isHands === 'Y'" label="2" border>手动下单</el-radio>
        </el-radio-group>
        <span style="margin-left: 20px;">如有疑问请联系商品中心处理</span>
      </div>
      <template v-if="otherObj.orderType === '2'">
        <div class="row align-center" style="margin-top: 20px;">
          <div style="width: 84px;text-align: right;margin-right: 5px;">
            <i style="color: red;margin-right: 5px;">*</i>
            <span>物流单号：</span>
          </div>
          <div class="row align-center" style="border: 1px solid #ddd;width: 400px;padding: 0 10px;border-radius: 4px;">
            <i class="iconfont icon-saoma" style="font-size: 24px;margin-right: 20px;"></i>
            <input
            type="text"
            ref="searchCode"
            placeholder="请扫描或输入快递单号"
            style="border: none;outline: none;line-height: 40px;flex: 1;background: none;font-size: 16px;"
            v-model="otherObj.waybillNo"
            v-focusInput="1"/>
          </div>
        </div>
        <div class="row align-center" style="margin-top: 20px;">
          <div style="width: 84px;text-align: right;margin-right: 5px;">
            <i style="color: red;margin-right: 5px;">*</i>
            <span>快递公司：</span>
          </div>
          <el-select placeholder="请选择" style="width: 400px;" v-model="otherObj.logisticsInfoId" filterable>
            <el-option v-for="(item, idx) in logisticsList" :key="idx" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </div>
      </template>
      <template v-if="otherObj.orderType === '1'">
        <div class="row align-center" style="margin-top: 20px;margin-bottom: 10px;">
          <div style="width: 84px;text-align: right;margin-right: 5px;">
            <span>预估重量：</span>
          </div>
          <div>
            <span>{{otherObj.weight}}公斤</span>
            <span>（根据货品资料克重计算）</span>
          </div>
        </div>
        <span style="margin-left: 13px;border-radius: 4px;background: rgb(255,247,230);padding: 2px 5px;color: rgb(212,107,8);">
          {{Number(otherObj.weight) > 30 ? '当前重量>30公斤，将下单京东快运' : '当前重量<30公斤，将下京东快递'}}
        </span>
      </template>
    </section>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="sureBefore">确 定</el-button>
    </div>
    <el-dialog
      title="填写收货信息"
      top="20vh"
      width="500px"
      append-to-body
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :visible.sync="addressVisible"
      @close="addressVisible = false">
      <section style="margin-top: -20px;">
        <el-form inline label-width="95px" size="small">
          <el-form-item required label="收件人姓名">
            <el-input placeholder="请输入" style="width: 350px;" v-model="addressObj.receiverName"></el-input>
          </el-form-item>
          <el-form-item required label="手机号">
            <el-input placeholder="请输入" style="width: 350px;" v-model="addressObj.phone"></el-input>
          </el-form-item>
          <el-form-item required label="省市区">
            <el-cascader
                placeholder="请选择"
                style="width: 350px;"
                v-model="otherObj.ssq"
                :props="{label: 'name', children: 'nodes', value: 'name'}"
                :options="addressArray"
                @change="getSelectAddress">
            </el-cascader>
          </el-form-item>
          <el-form-item required label="详细地址">
            <el-input placeholder="请输入" style="width: 350px;" v-model="addressObj.address"></el-input>
          </el-form-item>
        </el-form>
        <div style="margin: 10px 15px;">
          <p style="color: #0b83f3;margin-bottom: 5px;">自动解析</p>
          <el-input v-model="otherObj.addressData" type="textarea" :rows="3" placeholder="在此处粘贴整段地址，自动识别姓名，手机号码和地址例：张三，16478909988，广东省广州市番禺区明朗创意园" @change="onAddressInput"></el-input>
        </div>
      </section>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="addressVisible = false" type="primary">确 定</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import {queryDeptAddress} from "@/libs/http/modules/customer";
import AddressParse from "address-parse"
import {logisticsCompanyList, queryCityInfoList} from "@/libs/http/api";
import {getSalesOrderWeight, posSellSendVisitor} from "@/libs/http/modules/posOrder";
import {queryNegativeManualExpress} from "@/libs/http/modules/erp";
export default {
  name: 'sendGoods',
  props: {
    snData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dialogVisible: true,
      addressVisible: false,
      submitLoading: false,
      deptType: this.$cache.local.get('saleQuality'),
      logisticsList: [],
      isHands: '',
      addressArray: [],
      send: {
        receiverName: '',
        phone: '',
        province: '',
        city: '',
        area: '',
        address: ''
      },
      addressObj: {
        receiverName: '',
        phone: '',
        province: '',
        city: '',
        area: '',
        address: ''
      },
      otherObj: {
        orderType: '1',
        logisticsInfoId: '',
        waybillNo: '',
        addressData: '',
        weight: '0',
        ssq: []
      }
    }
  },
  created() {
    this.queryHands()
    this.getWeight()
    this.queryAddress()
    this.getAddressData()
    if(this.snData && this.snData.orderType === '2'){
      this.onAddressInput(this.snData.recipientAddress)
      this.addressObj.receiverName = this.snData.recipientName
      this.addressObj.phone = this.snData.recipientPhone
      this.otherObj.orderType = this.snData.orderType
      this.otherObj.waybillNo = this.snData.expressNo
      this.otherObj.logisticsInfoId = this.snData.logisticsId
    }
  },
  methods: {
    close() {
      this.dialogVisible = false
      this.$emit('close')
    },
    queryHands(){
      queryNegativeManualExpress().then(res=>{
        if(res === 'Y'){
          this.isHands = res
          this.fetchCompanyList()
        }
      }).catch(error=>{})
    },
    queryAddress () {
      queryDeptAddress({
        id: this.$cache.local.get('deptId')
      }).then(data => {
        if(data){
          this.send.receiverName = data.principal
          this.send.phone = data.principalPhone
          this.send.province = data.province
          this.send.city = data.city
          this.send.area = data.area
          this.send.address = data.address
        }
      }).catch(err => { })
    },
    getAddressData () {
      queryCityInfoList().then(data => {
        this.addressArray = data.nodes
      }).catch(err => {})
    },
    getSelectAddress(e){
      this.addressObj.province = e[0]
      this.addressObj.city = e[1]
      this.addressObj.area = e[2]
    },
    onAddressInput (text) {
      const parse = AddressParse.parse(text)
      if (parse && parse.length > 0) {
        const content = parse[0]
        this.addressObj.province = content.province
        this.addressObj.city = content.city
        this.addressObj.area = content.area
        this.addressObj.address = content.details
        this.addressObj.receiverName = content.name
        this.addressObj.phone = content.mobile
        this.otherObj.ssq = [content.province, content.city, content.area]
      }
    },
    fetchCompanyList() {
      logisticsCompanyList({
        page: 1,
        pageSize: 100,
        delState: 0
      }).then(data => {
        this.logisticsList = data.items || []
      }).catch(err => {})
    },
    getWeight(){
      getSalesOrderWeight({
        id: this.snData.invoicesId
      }).then(res=>{
        if(res){
          this.otherObj.weight = (res / 1000).toFixed(2)
        }
      }).catch(err=>{})
    },

    sureBefore(){
      for(let key in this.addressObj){
        if(!this.addressObj[key]){
          return this.$message({ showClose: true, message: '请填写收件方信息', type: "error", duration: 2000 });
        }
      }
      let reqData = {
        ...this.addressObj,
        orderType: this.otherObj.orderType,
        invoicesId: this.snData.invoicesId
      }
      if(this.otherObj.orderType === '2'){
        if(!this.otherObj.waybillNo){
          return this.$message({ showClose: true, message: '请填写物流单号', type: "error", duration: 2000 });
        }
        if(!this.otherObj.logisticsInfoId){
          return this.$message({ showClose: true, message: '请选择物流公司', type: "error", duration: 2000 });
        }
        reqData['waybillNo'] = this.otherObj.waybillNo
        reqData['logisticsInfoId'] = this.otherObj.logisticsInfoId
      }
      if(this.snData && this.snData.expressNo){
        reqData['id'] = this.snData.id
      }
      this.submitLoading = true
      posSellSendVisitor(reqData).then(res=>{
        this.close()
        this.$emit('refresh')
        this.submitLoading = false
      }).catch(error=>{
        this.submitLoading = false
        this.$message({ showClose: true, message: error, type: "error", duration: 2000 });
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-box{
  margin-top: -20px;
  .address-box{
    border: 1px solid #eee;
    .sn-row{
      background: rgb(238,241,251);
      padding: 10px;
      span{
        font-size: 16px;
        margin-right: 20px;
      }
      p{
        font-size: 24px;
        color: #0b83f3;
        font-weight: bold;
      }
    }
    .address-row{
      margin-bottom: 20px;
      .address-item{
        margin: 30px;
        position: relative;
        width: calc(100% / 2);
        &:last-child{
          margin-left: 0;
        }
        .item-left{
          width: 100px;
          height: 100px;
          border-radius: 4px;
          background: rgb(235,247,255);
          text-align: center;
          line-height: 100px;
          font-size: 18px;
          margin-right: 10px;
          font-weight: bold;
        }
        .item-right{
          flex: 1;
          height: 100px;
          .name{
            font-size: 20px;
            color: #000;
            font-weight: bold;
          }
          .phone{
            color: #000;
            font-size: 16px;
          }
          .address{
            color: #999;
          }
        }
        .change-address{
          position: absolute;
          left: 8px;
          cursor: pointer;
          bottom: -20px;
          color: #0b83f3;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>