var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":"客订提示","width":"60%","top":"20vh","close-on-click-modal":false,"visible":_vm.dialogVisible,"append-to-body":""},on:{"update:visible":function($event){_vm.dialogVisible=$event},"close":_vm.close}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v("当前客订货品已有预计库存，是否继续走客订")]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"header row"},[_c('div',{staticClass:"row align-center space-center",staticStyle:{"width":"120px","border":"1px solid #eee"}},[_vm._v(" 货品名称 ")]),_c('div',{staticClass:"row align-center space-center",staticStyle:{"width":"120px","border":"1px solid #eee"}},[_vm._v(" 货号 ")]),_c('div',{staticClass:"row align-center space-center",staticStyle:{"width":"120px","border":"1px solid #eee"}},[_vm._v(" 颜色 ")]),_c('div',{staticClass:"size-title"},_vm._l((_vm.sizeGroup),function(item,row){return _c('div',{key:row,staticClass:"row align-center"},_vm._l((item.valueInfoList),function(size){return _c('span',{key:size.code,style:(item.valueGroupId === _vm.valueGroupId
                ? 'color:#fff;background: rgb(191,231,250);'
                : '')},[_vm._v(_vm._s(size.value))])}),0)}),0),_c('div',{staticClass:"row align-center space-center",staticStyle:{"width":"70px","border":"1px solid #eee"}},[_vm._v(" 合计 ")])]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"body"},_vm._l((_vm.goodsList),function(item,line){return _c('div',{key:line,staticClass:"row",style:({
          background:
            _vm.valueGroupId === item.sizeGroupId ? 'rgb(249,250,252)' : '',
        }),on:{"click":function($event){_vm.valueGroupId = item.sizeGroupId}}},[_c('div',{staticClass:"row align-center space-center",staticStyle:{"width":"120px","border":"1px solid #eee"}},[_vm._v(" "+_vm._s(item.goodsName)+" ")]),_c('div',{staticClass:"row align-center space-center",staticStyle:{"width":"120px","border":"1px solid #eee"}},[_vm._v(" "+_vm._s(item.goodsNo)+" ")]),_c('div',{staticClass:"row align-center space-center",staticStyle:{"width":"120px","border":"1px solid #eee"}},[_vm._v(" "+_vm._s(item.color)+" ")]),_c('div',{staticClass:"row align-center",staticStyle:{"flex":"1","border":"1px solid #eee"}},[_c('div',{staticStyle:{"width":"80px","height":"120px"}},[_c('div',{staticClass:"row align-center space-center",staticStyle:{"height":"30px","border-bottom":"1px solid #eee"}},[_vm._v(" 可用库存 ")]),_c('div',{staticClass:"row align-center space-center",staticStyle:{"height":"30px","border-top":"1px solid #eee","border-bottom":"1px solid #eee"}},[_vm._v(" 在途库存 ")]),_c('div',{staticClass:"row align-center space-center",staticStyle:{"height":"30px","border-bottom":"1px solid #eee","border-top":"1px solid #eee"}},[_vm._v(" 已配未发 ")]),_c('div',{staticClass:"row align-center space-center",staticStyle:{"height":"30px","border-top":"1px solid #eee"}},[_vm._v(" 预计库存 ")])]),_c('div',{staticClass:"size-value"},[_c('div',{staticClass:"row align-center"},_vm._l((item.goodsStockInfoList),function(val,x){return _c('span',{key:x,staticStyle:{"border-top":"none"},style:(val.hasColoe &&
                  val.usableQuantity &&
                  +val.usableQuantity > 0
                    ? 'background:#F8475F;color:#fff;'
                    : '')},[_vm._v(_vm._s(val.usableQuantity))])}),0),_c('div',{staticClass:"row align-center"},_vm._l((item.goodsStockInfoList),function(val,x){return _c('span',{key:x,staticStyle:{"border-bottom":"1px solid #eee"},style:(val.hasColoe &&
                  val.inTransitQuantity &&
                  +val.inTransitQuantity > 0
                    ? 'background:#F8475F;color:#fff;'
                    : '')},[_vm._v(_vm._s(val.inTransitQuantity))])}),0),_c('div',{staticClass:"row align-center"},_vm._l((item.goodsStockInfoList),function(val,x){return _c('span',{key:x,staticStyle:{"border-top":"1px solid #eee"},style:(val.hasColoe &&
                  val.notSentQuantity &&
                  +val.notSentQuantity > 0
                    ? 'background:#F8475F;color:#fff;'
                    : '')},[_vm._v(_vm._s(val.notSentQuantity))])}),0),_c('div',{staticClass:"row align-center"},_vm._l((item.goodsStockInfoList),function(val,x){return _c('span',{key:x,staticStyle:{"border-bottom":"none"},style:(val.hasColoe &&
                  val.predictQuantity &&
                  +val.predictQuantity > 0
                    ? 'background:#F8475F;color:#fff;'
                    : '')},[_vm._v(_vm._s(val.predictQuantity))])}),0)])]),_c('div',{staticStyle:{"width":"70px","border":"1px solid #eee"}},[_c('div',{staticStyle:{"height":"30px","line-height":"30px","border-bottom":"1px solid #eee"}},[_vm._v(" "+_vm._s(item.totalOne)+" ")]),_c('div',{staticStyle:{"height":"30px","line-height":"30px","border-top":"1px solid #eee","border-bottom":"1px solid #eee"}},[_vm._v(" "+_vm._s(item.totalTwo)+" ")]),_c('div',{staticStyle:{"height":"30px","line-height":"30px","border-bottom":"1px solid #eee","border-top":"1px solid #eee"}},[_vm._v(" "+_vm._s(item.totalThree)+" ")]),_c('div',{staticStyle:{"height":"30px","line-height":"30px","border-top":"1px solid #eee"}},[_vm._v(" "+_vm._s(item.totalFour)+" ")])])])}),0)]),_c('span',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.close()}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.close('refresh')}}},[_vm._v("确 定")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }