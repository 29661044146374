<template>
  <dialogModule
    top="3"
    width="80%"
    title="改价申请"
    v-if="changPriceVisible"
    :closeOnPressEscape="false"
    :dialogVisible="changPriceVisible"
    @close="close">
    <span slot="close" class="el-icon-close close-icon" @click="close"></span>
    <section slot="content" class="content-dialog">
      <div class="section-form">
        <div class="card-title row align-center">
          <i></i>
          <span>申请单信息</span>
        </div>
        <div class="row align-center">
          <div class="row align-center form-item">
            <div>提交人</div>
            <el-select class="value" size="small" v-model="form.userId" placeholder="请选择">
              <el-option v-for="item in userList" :key="item.id" :label="item.realName" :value="item.id"> </el-option>
            </el-select>
          </div>
          <div class="row align-center form-item">
            <div>提交时间</div>
            <el-date-picker
              disabled
              class="value"
              size="small"
              v-model="form.date"
              type="datetime"
              placeholder="选择日期时间">
            </el-date-picker>
          </div>
          <div class="row align-center form-item">
            <div>店铺名称</div>
            <el-input disabled class="value" size="small" v-model="form.deptName"></el-input>
          </div>
          <div class="row align-center form-item">
            <div>原价</div>
            <el-input disabled class="value" size="small" v-model="form.oldMoneyAll"></el-input>
          </div>
        </div>
        <div class="row align-center">
          <div class="row align-center form-item">
            <div>改后金额</div>
            <el-input disabled class="value" size="small" v-model="form.newMoneyAll"></el-input>
          </div>
          <div class="row align-center form-item">
            <div>变动金额</div>
            <el-input disabled class="value" size="small" v-model="form.changePrice"></el-input>
          </div>
        </div>
        <div class="row form-item">
          <div>备注</div>
          <el-input
            class="text-area"
            type="textarea"
            rows="3"
            placeholder="请输入"
            v-model="form.description"></el-input>
        </div>
      </div>
      <div class="section-goods">
        <div class="card-title row align-center">
          <i></i>
          <span>改价货品</span>
        </div>
        <div class="goods-table">
          <grid-manager :option="gridOption"></grid-manager>
        </div>
      </div>
      <div class="section-flows">
        <FlowSteps :flowTypeId="hrFlowTypeId"></FlowSteps>
      </div>
      <div class="row space-center align-center action-btn">
        <el-button class="btn" @click="close" :disabled="loading">取 消</el-button>
        <el-button
          v-if="!approveFail"
          class="btn"
          type="primary"
          :disabled="loading"
          :loading="loading"
          @click="sureSubmit"
          >确 认</el-button
        >
        <el-button v-else class="btn" type="primary" :disabled="loading" :loading="loading" @click="approveAgain"
          >确 认</el-button
        >
      </div>
    </section>
  </dialogModule>
</template>
<script>
import moment from 'moment'
import FlowSteps from '@/components/FlowSteps.vue'
import dialogModule from '@/components/dialogModule.vue'
import { posSubmitPosAlterPrice, queryShoppGuide, returnSalesSubmit } from '@/libs/http/modules/posOrder'
export default {
  name: 'returnChangePrice',
  props: {
    approveList: {
      type: Array,
      default: () => []
    },
    approveInfo: {
      type: Object,
      default: () => {}
    },
    tableData: {
      type: Array,
      default: () => []
    },
    reduceResult: {
      type: Object,
      default: () => {}
    },
    hrFlowTypeId: {
      type: String,
      default: () => ''
    },
    snNo: {
      type: String,
      default: () => ''
    }
  },
  components: {
    FlowSteps,
    dialogModule
  },
  data() {
    return {
      changPriceVisible: true,
      form: {
        userId: '',
        date: moment().format('yyyy-MM-DD HH:mm'),
        deptName: this.$cache.local.get('deptName'),
        description: '',
        oldMoneyAll: '',
        newMoneyAll: '',
        changePrice: ''
      },
      back: require('@/assets/images/return.png'),
      gridOption: {
        gridManagerName: 'return-goods-change',
        firstLoading: true,
        height: 'auto',
        isCombSorting: true,
        supportAutoOrder: false,
        supportCheckbox: false,
        supportMenu: false,
        supportConfig: true,
        disableAutoLoading: true,
        disableBorder: false,
        disableLine: true,
        columnData: [
          {
            key: 'operationType',
            text: '类型',
            align: 'center',
            width: '60px',
            template: () => {
              return `
								<div>
								    <img style="width:40px;height:40px;" :src="back" />
								</div>
						    `
            }
          },
          {
            key: 'goodsInfo',
            text: '货品信息',
            align: 'center',
            width: '280px',
            template: () => {
              return `
								<div class="row" style="text-align:left;">
									<div style="width:102px;height:92px;margin-right:10px;position: relative;overflow: hidden;">
										<el-image v-if="row.imageList && row.imageList.length" style="width:102px;height:92px;border-radius:4px;" :preview-src-list="row.imageList" :src="row.imageList[0]"></el-image>
								    	<div v-else class="row align-center space-center" style="background: #eee;width:102px;height:92px;border-radius:4px;color: #999;">暂无图片</div>
								    </div>
								    <div class="column space-between" style="height:90px;">
										<div style="font-size:18px;color:#333;">{{row.goodsName}}</div>
										<div style="font-size:14px;color:#999;">货号：{{row.goodsNo}}</div>
										<div style="font-size:14px;color:#999;">条码：{{row.barCode}}</div>
								    </div>
								</div>
							`
            }
          },
          {
            key: 'attr',
            text: '颜色 / 尺码',
            align: 'center',
            width: '140px',
            template: () => {
              return `
								<div v-if="row.attr" class="row space-center align-center text-center" style="border:1px solid #DEEEF9;background:#fff;line-height:34px;border-radius:2px;">
									<p v-for="(item, index) in row.attr.slice(0,2)" :key="item.code">{{item.value}}
									  	<span v-if="index == 0" style="margin:0 3px;">,</span>
									</p>
							  	</div>
                    		`
            }
          },
          {
            key: 'tagPrice',
            text: '吊牌价',
            align: 'center',
            template: () => {
              return `
								<div style="font-size: 18px;">￥{{row.tagPrice}}</div>
							`
            }
          },
          {
            key: 'defaultDiscount',
            text: '折扣',
            align: 'center',
            template: () => {
              return `
								<div style="font-size: 18px;">{{(row.realPrice / row.tagPrice * 10).toFixed(1)}}折</div>
							`
            }
          },
          {
            key: 'retreatNumber',
            text: '数量',
            align: 'center',
            template: () => {
              return `
								<div style="font-size: 18px;">{{row.retreatNumber}}</div>
							`
            }
          },
          {
            key: 'realPrice',
            text: '原单卖价',
            align: 'center',
            template: () => {
              return `
								<div style="font-size: 18px;color:rgb(255,158,0);">￥{{row.realPrice}}</div>
							`
            }
          },
          {
            key: 'originPrice',
            text: '改前单价',
            align: 'center',
            template: () => {
              return `
								<div style="font-size: 18px;color:rgb(255,158,0);">￥{{row.originPrice}}</div>
							`
            }
          },
          {
            key: 'retreatPrice',
            text: '改后单价',
            align: 'center',
            template: () => {
              return `
								<div style="color:#F8475F;font-size: 18px;">￥{{row.retreatPrice}}</div>
						    `
            }
          },
          {
            key: 'totalPrice',
            text: '改前总价',
            align: 'center',
            template: () => {
              return `
								<div style="font-size: 18px;color:rgb(255,158,0);">￥{{row.totalPrice}}</div>
						    `
            }
          },
          {
            key: 'totalRetreatPrice',
            text: '改后总价',
            align: 'center',
            template: () => {
              return `
								<div style="color:#F8475F;font-size: 18px;">￥{{row.totalRetreatPrice}}</div>
						    `
            }
          }
        ],
        ajaxData: this.showGoodsTable
      },
      goodsList: [],
      userList: [],
      changeValue: 0,
      loading: false,
      ordingId: '',
      approveFail: false
    }
  },
  created() {
    this.goodsList = this.approveList
    this.form.oldMoneyAll = this.approveList.reduce((c, R) => c + Number(R.totalPrice || 0), 0)
    this.form.newMoneyAll = this.approveList.reduce((c, R) => c + Number(R.totalRetreatPrice || 0), 0)
    this.changeValue = (this.form.newMoneyAll - this.form.oldMoneyAll).toFixed(2)
    this.form.changePrice =
      this.changeValue > 0 ? `增加${Math.abs(this.changeValue)}` : `减少${Math.abs(this.changeValue)}`
    this.queryGuide()
  },
  methods: {
    close() {
      if (this.loading) {
        return this.$message({ type: 'error', message: '操作正在进行中，不能关闭', duration: 2000, showClose: true })
      }
      this.changPriceVisible = false
      this.$emit('close')
    },
    showGoodsTable() {
      return new Promise((resolve, reject) => {
        const tableData = {
          data: this.goodsList
        }
        resolve(tableData)
      })
    },
    queryGuide() {
      queryShoppGuide({
        page: '1',
        pageSize: '50'
      })
        .then(res => {
          this.userList = res.items || []
          if (this.approveInfo.userName) {
            this.userList.forEach(item => {
              if (item.realName === this.approveInfo.userName) {
                this.form.userId = item.id
              }
            })
          }
        })
        .catch(() => {})
    },
    sureSubmit() {
      if (!this.form.userId) {
        return this.$message({ type: 'error', message: '请选择申请人', duration: 2000, showClose: true })
      }
      if (this.$cache.local.get('saleQuality') === 'ZY' && !this.form.description) {
        return this.$message({ type: 'error', message: '请填写审批备注', duration: 2000, showClose: true })
      }
      this.approveFail = false
      this.loading = true
      let reqData = {
        hasAlterPriceInfo: 'Y',
        goodInfos: this.tableData,
        reduceResult: this.reduceResult,
        remark: this.approveInfo.remark,
        orderId: this.approveInfo.orderId,
        orderTypeId: this.approveInfo.orderTypeId
      }
      const cashierItem = JSON.parse(this.$cache.local.get('cashierDeskItem'))
      if (cashierItem) {
        reqData['terminalId'] = cashierItem.id
      }
      returnSalesSubmit(reqData)
        .then(res => {
          this.ordingId = res.id
          this.submitApprove()
        })
        .catch(error => {
          this.loading = false
          this.$message({ showClose: true, message: error, type: 'error', duration: 2000 })
        })
    },
    submitApprove() {
      let list = this.goodsList.map(item => {
        return {
          sku: item.sku,
          number: item.number,
          beforePrice: item.originPrice,
          afterPrice: item.retreatPrice,
          oldPrice: item.realPrice
        }
      })
      posSubmitPosAlterPrice({
        alterType: '1',
        goodsInfos: list,
        invoicesId: this.ordingId,
        submitUser: this.form.userId,
        hrFlowTypeId: this.hrFlowTypeId,
        description: this.form.description,
        oldInnerSn: this.snNo
      })
        .then(data => {
          this.loading = false
          this.changPriceVisible = false
          this.$emit('sure')
          this.$message({ type: 'success', message: '改价申请已提交', duration: 2000, showClose: true })
        })
        .catch(err => {
          this.loading = false
          this.approveFail = true
          this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
        })
    },
    approveAgain() {
      this.loading = true
      this.submitApprove()
    }
  }
}
</script>
<style lang="scss" scoped>
.close-icon {
  color: #999;
  padding: 10px;
  margin-right: -10px;
  cursor: pointer;
}
.content-dialog {
  height: 84vh;
  overflow: auto;
}
.card-title {
  i {
    width: 4px;
    height: 16px;
    background: #0b83f3;
    border-radius: 2px;
    margin-right: 6px;
  }
  span {
    font-size: 16px;
  }
}
.section-form {
  margin: 15px 20px;
  > div {
    margin-bottom: 20px;
  }
  .form-item {
    margin-right: 20px;
    div {
      width: 60px;
      text-align: right;
      margin-right: 5px;
    }
    .value {
      width: 220px;
    }
    .text-area {
      width: 100%;
    }
  }
}
.section-goods {
  border-top: 1px solid #eee;
  padding: 15px 20px;
  .goods-table {
    margin-top: 15px;
  }
}
.section-flows {
  border-top: 1px solid #eee;
  padding: 10px;
}
.action-btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  .btn {
    width: 100px;
  }
}
</style>
