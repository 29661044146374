<template>
  <dialogModule
    top="3"
    width="80%"
    v-if="exchangeVisible"
    :title="dialogTitle"
    :closeOnPressEscape="false"
    :dialogVisible="exchangeVisible"
    @close="close">
    <span slot="close" class="el-icon-close close-icon" @click="close"></span>
    <div
      slot="content"
      class="exchange-box"
      v-loading="fetchLoading"
      element-loading-text="请稍后，全力加载中..."
      element-loading-spinner="el-icon-loading">
      <section class="row">
        <div class="exchange-left table-style">
          <el-table
            height="100%"
            :data="tableData"
            :header-cell-style="{
              padding: '5px',
              color: '#666',
              background: '#eee'
            }"
            :cell-style="{
              color: '#666',
              padding: '5px 0'
            }">
            <el-table-column type="expand">
              <template slot-scope="scope">
                <el-table
                  border
                  class="table-table"
                  :data="scope.row.miniGoodInfos"
                  :header-cell-style="{
                    color: '#666',
                    padding: '5px',
                    background: '#fafafa'
                  }"
                  :cell-style="{
                    color: '#666',
                    padding: '5px 0'
                  }">
                  <el-table-column prop="images" label="货品图片" align="center">
                    <template slot-scope="cscope">
                      <div class="row align-center space-center">
                        <el-image
                          class="cs-image"
                          v-if="cscope.row.imageList"
                          :src="cscope.row.imageList[0]"
                          :preview-src-list="cscope.row.imageList"></el-image>
                        <div class="cs-image row align-center space-center" v-else>暂无图片</div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="goodsNo" label="货号" width="100" align="center"> </el-table-column>
                  <el-table-column prop="barCode" width="150" label="条码" align="center"> </el-table-column>
                  <el-table-column prop="images" label="颜色" width="100" align="center">
                    <template slot-scope="cscope">
                      <span v-if="cscope.row.attr">{{ cscope.row.attr[0].value }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="images" label="尺码" align="center">
                    <template slot-scope="cscope">
                      <span v-if="cscope.row.attr">{{ cscope.row.attr[1].value }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="number" label="数量" align="center"> </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column prop="type" label="类型" align="center">
              <template slot-scope="scope">
                <img
                  style="width: 40px; height: 40px"
                  v-if="scope.row.operationType === '1'"
                  src="@/assets/images/sell.png" />
                <img
                  style="width: 40px; height: 40px"
                  v-if="scope.row.operationType === '2'"
                  src="@/assets/images/give.png" />
                <img
                  style="width: 40px; height: 40px"
                  v-if="scope.row.operationType === '4'"
                  src="@/assets/images/return.png" />
                <img
                  style="width: 40px; height: 40px"
                  v-if="scope.row.operationType === '3'"
                  src="@/assets/images/rite.png" />
              </template>
            </el-table-column>
            <el-table-column prop="goods" label="货品信息" align="center" width="280px">
              <template slot-scope="scope">
                <div class="row">
                  <div class="row left-goods">
                    <el-image
                      class="change-img"
                      v-if="scope.row.imageList && scope.row.imageList[0]"
                      :src="scope.row.imageList[0]"
                      :preview-src-list="scope.row.imageList"></el-image>
                    <div v-else class="row align-center space-center change-img">暂无图片</div>
                    <div
                      v-if="scope.row.activityId || scope.row.isExchange === 1 || scope.row.operationType === '2'"
                      :class="[
                        'label-tips',
                        scope.row.isExchange === 1 ? 'add-buy' : '',
                        scope.row.operationType === '2' ? 'gift' : '',
                        scope.row.activityId ? 'activity' : ''
                      ]">
                      <span>{{
                        scope.row.isExchange === 1 ? '加购' : scope.row.operationType === '2' ? '赠品' : '促销'
                      }}</span>
                    </div>
                  </div>
                  <div class="column space-between change-info">
                    <p>{{ scope.row.goodsName }}</p>
                    <span>货号：{{ scope.row.goodsNo }}</span>
                    <span>条码：{{ scope.row.barCode }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="colorSize" label="颜色尺码" align="center" width="120px">
              <template slot-scope="scope">
                <div v-if="scope.row.attr" class="row space-center align-center text-center color-size">
                  <p v-for="(item, index) in scope.row.attr.slice(0, 2)" :key="item.code">
                    {{ item.value }}
                    <span v-if="index === 0" style="margin: 0 3px">,</span>
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="realPrice" label="单价" align="center">
              <template slot-scope="scope">
                <div>￥{{ scope.row.realPrice }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="number" label="数量" align="center"> </el-table-column>
            <el-table-column prop="totalPrice" label="合计金额" align="center">
              <template slot-scope="scope">
                <div class="red-text">￥{{ scope.row.totalPrice }}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <span
                  v-if="scope.row.isValidChange === 'Y'"
                  class="change-goods"
                  @click="changeGoods(scope.$index, scope.row)"
                  >换货</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="exchange-right">
          <div v-if="!isSelectChange" class="select-state">
            <noDataState text-desc="未选择换货货品" margin-top="200"></noDataState>
          </div>
          <div v-else class="select-state has-select">
            <div class="row space-between align-center select-top">
              <p>换货货品</p>
              <span v-if="currRow.activityName">促销活动：{{ currRow.activityName }}</span>
            </div>
            <div class="target-goods row align-center">
              <div class="row align-center">
                <img
                  style="width: 40px; height: 40px"
                  v-if="scope.row.operationType === '1'"
                  src="@/assets/images/sell.png" />
                <img
                  style="width: 40px; height: 40px"
                  v-if="scope.row.operationType === '2'"
                  src="@/assets/images/give.png" />
                <img
                  style="width: 40px; height: 40px"
                  v-if="scope.row.operationType === '4'"
                  src="@/assets/images/return.png" />
                <img
                  style="width: 40px; height: 40px"
                  v-if="scope.row.operationType === '3'"
                  src="@/assets/images/rite.png" />
                <div class="row align-center goods-info">
                  <el-image
                    class="image-goods"
                    v-if="currRow.imageList && currRow.imageList[0]"
                    :src="currRow.imageList[0]"
                    :preview-src-list="currRow.imageList"></el-image>
                  <div v-else class="row align-center space-center image-goods">暂无图片</div>
                  <div class="column space-between goods-data">
                    <p>{{ currRow.goodsName }}</p>
                    <span>货号：{{ currRow.goodsNo }}</span>
                    <span>条码：{{ currRow.barCode }}</span>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <div class="color-size">
                  <span>{{ currRow.attr[0].value }}，</span>
                  <span>{{ currRow.attr[1].value }}</span>
                </div>
                <div class="number">
                  数量：<span>{{ currRow.number }}</span>
                </div>
              </div>
            </div>
            <div class="row align-center space-between add-change">
              <div class="scan-input row align-center">
                <i class="iconfont icon-saomafukuan-"></i>
                <input
                  v-focus-input="1"
                  type="text"
                  ref="scanInput"
                  placeholder="请扫描或输入条码添加货品"
                  v-model="scanValue"
                  :disabled="scanDisable"
                  @keyup.enter="sureAdd" />
                <span @click="sureAdd">确定</span>
              </div>
              <div class="choose-add" @click="handsAdd">手动添加</div>
            </div>
            <div class="table-style">
              <el-table
                max-height="460px"
                :data="currRow.miniGoodInfos"
                :header-cell-style="{
                  padding: '5px',
                  color: '#666',
                  background: '#eee'
                }"
                :cell-style="{
                  color: '#666',
                  padding: '5px 0'
                }">
                <el-table-column prop="goods" label="替换货品" align="center" width="285px">
                  <template slot-scope="scope">
                    <div class="row">
                      <el-image
                        class="change-img"
                        v-if="scope.row.imageList"
                        :src="scope.row.imageList[0]"
                        :preview-src-list="scope.row.imageList"></el-image>
                      <div v-else class="row align-center space-center change-img">暂无图片</div>
                      <div class="column space-between change-info">
                        <p>{{ scope.row.goodsName }}</p>
                        <span>货号：{{ scope.row.goodsNo }}</span>
                        <span>条码：{{ scope.row.barCode }}</span>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="colorSize" label="颜色尺码" align="center" width="130px">
                  <template slot-scope="scope">
                    <div class="color-size" v-if="scope.row.attr">
                      {{ scope.row.attr[0].value }}，{{ scope.row.attr[1].value }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="number" label="数量" align="center"> </el-table-column>
                <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                    <span class="red-text cursor" @click="deleteRow(scope.$index, scope.row)">删除</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="chang-desc">
            <p><span>*</span>请描述换货原因：</p>
            <el-input type="textarea" v-model="description" placeholder="请输入" resize="none" rows="3"></el-input>
          </div>
          <div class="row align-center action-btn">
            <el-button class="reset-goods" :disabled="!isSelectChange" @click="reelect">重新选择换货货品</el-button>
            <el-button
              class="sure-change"
              type="primary"
              :disabled="!isSelectChange"
              :loading="fetchLoading"
              @click="beforeSubmit"
              >确定换货</el-button
            >
          </div>
        </div>
      </section>
      <ActChange
        v-if="chooseAttrVisible"
        :activityId="currRow.activityId"
        :barCode="currRow.barCode"
        :maxNumber="maxNumber"
        :isAllowNegativeStocks="isAllowNegativeStocks"
        @close="chooseAttrVisible = false"
        @confirm="handsSure">
      </ActChange>
      <AttrDialog
        v-if="attrVisible"
        :barCode="currRow.barCode"
        :maxNumber="maxNumber"
        :isColorSize="true"
        :isAllowNegativeStocks="isAllowNegativeStocks"
        @close="attrVisible = false"
        @sure="handsSure">
      </AttrDialog>
    </div>
  </dialogModule>
</template>

<script>
import ActChange from './ActChange'
import AttrDialog from './AttrDialog'
import noDataState from '@/components/noDataState'
import dialogModule from '@/components/dialogModule.vue'
import { activitySubmitGoods, colorSizeSubmitGoods, salesAfterOperate } from '@/libs/http/modules/posOrder'
export default {
  name: 'exchangeGoods',
  props: {
    invoicesItem: {
      type: Object,
      default: () => null
    }
  },
  components: {
    ActChange,
    AttrDialog,
    noDataState,
    dialogModule
  },
  data() {
    return {
      attrVisible: false,
      fetchLoading: false,
      exchangeVisible: true,
      chooseAttrVisible: false,
      isSelectChange: false,
      scanDisable: false,
      description: '',
      tableData: [],
      goodInfos: [],
      currRow: null,
      currIndex: 0,
      scanValue: '',
      maxNumber: 0,
      isAllowNegativeStocks: ''
    }
  },
  computed: {
    dialogTitle() {
      if (this.invoicesItem.type === '2') {
        return '促销换货'
      } else {
        return '色码换货'
      }
    }
  },
  created() {
    let operateGoodInfo = {
      alterGoodsWay: '4'
    }
    this.operateList(operateGoodInfo)
  },
  methods: {
    close() {
      this.exchangeVisible = false
      this.$emit('close')
    },
    changeGoods(index, row) {
      if (row.isValidChange !== 'Y' && this.invoicesItem.type === '2') {
        this.$confirm('该货品参与的促销活动已结束，不支持换货', '提示', {
          showCancelButton: false,
          closeOnPressEscape: false,
          closeOnClickModal: false,
          confirmButtonText: '知道了'
        })
          .then(res => {})
          .catch(() => {})
      } else {
        this.currRow = row
        this.currIndex = index
        this.isSelectChange = true
        this.$nextTick(() => {
          this.$refs.scanInput.focus()
        })
      }
    },
    sureAdd() {
      if (!this.scanValue || !this.scanValue.trim()) return
      if (this.scanValue === this.currRow.barCode) {
        this.scanValue = ''
        return this.$message({ showClose: true, message: '不能换相同颜色尺码的货品', type: 'error', duration: 2000 })
      }
      const number = this.currRow.miniGoodInfos.reduce((c, R) => c + Number(R.number || 0), 0)
      if (Number(number) >= Number(this.currRow.number)) {
        this.scanValue = ''
        return this.$message({ showClose: true, message: '换货数量不能超过原货品数量', type: 'error', duration: 2000 })
      }
      let operateGoodInfo = {
        number: '1',
        alterGoodsWay: '1',
        index: this.currIndex,
        barCode: this.scanValue
      }
      this.operateList(operateGoodInfo)
    },
    handsAdd() {
      this.maxNumber = this.currRow.number - this.currRow.miniGoodInfos.reduce((c, R) => c + Number(R.number || 0), 0)
      if (this.maxNumber === 0) {
        return this.$message({
          showClose: true,
          message: '已达换货最大数量，不能再添加了',
          type: 'error',
          duration: 2000
        })
      }
      if (this.invoicesItem.type === '2') {
        this.chooseAttrVisible = true
      } else {
        this.attrVisible = true
      }
    },
    handsSure(e) {
      this.attrVisible = false
      this.chooseAttrVisible = false
      let operateGoodInfo = {
        number: e.number,
        alterGoodsWay: '1',
        barCode: e.barCode,
        index: this.currIndex
      }
      this.operateList(operateGoodInfo)
    },
    deleteRow(index, row) {
      this.$confirm('是否删除该替换货品吗？', '提示')
        .then(res => {
          let operateGoodInfo = {
            alterGoodsWay: '2',
            number: row.number,
            barCode: row.barCode,
            index: this.currIndex
          }
          this.operateList(operateGoodInfo)
        })
        .catch(err => {})
    },
    reelect() {
      if (!this.currRow || !this.currRow.miniGoodInfos.length) {
        return this.$message({ showClose: true, message: '您还没有添加换货货品', type: 'error', duration: 2000 })
      }
      this.$confirm('确定要重新选择换货货品', '提示')
        .then(res => {
          let list = this.currRow.miniGoodInfos.map(item => {
            return item.barCode
          })
          let operateGoodInfo = {
            alterGoodsWay: '2',
            index: this.currIndex,
            barCode: list.join(',')
          }
          this.operateList(operateGoodInfo)
        })
        .catch(() => {})
    },
    operateList(operateGoodInfo) {
      this.fetchLoading = true
      let reqData = {
        orderId: this.invoicesItem.id,
        operationOrderType: this.invoicesItem.type
      }
      if (operateGoodInfo) {
        reqData['operateGoodInfo'] = operateGoodInfo
      }
      if (this.goodInfos.length) {
        reqData['goodInfos'] = this.goodInfos
      }
      salesAfterOperate(reqData)
        .then(res => {
          this.scanValue = ''
          if (res && res.goodInfos) {
            this.tableData = res.goodInfos
            this.goodInfos = JSON.parse(JSON.stringify(res.goodInfos))
            this.currRow = this.tableData[this.currIndex]
          }
          if (res && res.message && res.message !== '') {
            this.$message.error(res.message)
          }
          if (!this.isAllowNegativeStocks) {
            this.isAllowNegativeStocks = res.isAllowNegativeStocks
          }
          this.fetchLoading = false
        })
        .catch(error => {
          this.scanValue = ''
          this.fetchLoading = false
          this.$message({ showClose: true, message: error, type: 'error', duration: 2000 })
        })
    },
    beforeSubmit() {
      let res = this.tableData.some(item => {
        return item.miniGoodInfos && item.miniGoodInfos.length
      })
      if (!res) {
        return this.$message({ showClose: true, message: '请添加要换货的货品', type: 'error', duration: 2000 })
      }
      if (!this.description) {
        return this.$message({ showClose: true, message: '请填写换货原因', type: 'error', duration: 2000 })
      }
      this.fetchLoading = true
      let reqData = {
        remark: this.description,
        goodInfos: this.tableData,
        orderId: this.invoicesItem.id,
        orderTypeId: this.invoicesItem.orderTypeId
      }
      const cashierItem = JSON.parse(this.$cache.local.get('cashierDeskItem'))
      if (cashierItem) {
        reqData['terminalId'] = cashierItem.id
      }
      if (this.invoicesItem.type === '2') {
        this.activeSubmit(reqData)
      } else {
        this.colorSizeSubmit(reqData)
      }
    },
    activeSubmit(reqData) {
      activitySubmitGoods(reqData)
        .then(res => {
          this.exchangeVisible = false
          this.$emit('refresh')
          this.fetchLoading = false
        })
        .catch(error => {
          this.fetchLoading = false
          this.$message({ showClose: true, message: error, type: 'error', duration: 2000 })
        })
    },
    colorSizeSubmit(reqData) {
      colorSizeSubmitGoods(reqData)
        .then(res => {
          this.exchangeVisible = false
          this.$emit('refresh')
          this.fetchLoading = false
        })
        .catch(error => {
          this.fetchLoading = false
          this.$message({ showClose: true, message: error, type: 'error', duration: 2000 })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.close-icon {
  color: #999;
  padding: 10px;
  margin-right: -10px;
  cursor: pointer;
}
.table-style {
  border: 1px solid #eee;
  border-bottom: none;
  .change-img {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    color: #999;
    background: #eee;
    float: left;
  }
  .change-info {
    text-align: left;
    margin-left: 10px;
    height: 80px;
    p {
      font-size: 16px;
      color: #000;
    }
  }
  .color-size {
    border: 1px solid #eee;
    padding: 5px 0;
  }
  .red-text {
    color: red;
  }
  .change-goods {
    color: #fff;
    cursor: pointer;
    background: #0b83f3;
    padding: 5px 10px;
    border-radius: 2px;
  }
  .table-table {
    width: 70%;
    margin-left: 15%;
  }
  .cs-image {
    width: 60px;
    height: 26px;
    float: left;
    background: #eee;
    color: #999;
    font-size: 12px;
  }
  .left-goods {
    position: relative;
    overflow: hidden;
  }
  .label-tips {
    position: absolute;
    left: 15px;
    top: 5px;
    width: 100px;
    line-height: 20px;
    color: #fff;
    transform: rotate(45deg);
    text-align: center;
    font-size: 12px;
  }
}

.exchange-box {
  padding: 10px;
  .exchange-left {
    width: calc(100% - 610px);
  }
  .exchange-right {
    width: 600px;
    margin-left: 10px;
    .select-state {
      height: 66vh;
      border-radius: 4px;
      border: 1px solid #ddd;
    }
    .has-select {
      .select-top {
        margin: 10px;
        p {
          font-size: 18px;
          color: #000;
        }
      }
      .target-goods {
        margin: 20px auto;
        img {
          margin: 0 10px;
        }
        .goods-info {
          margin-right: 50px;
          .image-goods {
            width: 80px;
            height: 80px;
            background: #eee;
            border-radius: 4px;
            color: #999;
            margin: 0 10px;
          }
          .goods-data {
            height: 80px;
            p {
              font-size: 17px;
              color: #000;
            }
          }
        }
        .color-size {
          border: 1px solid #eee;
          padding: 0 15px;
          line-height: 30px;
          color: #000;
          margin-bottom: 10px;
        }
        .number {
          span {
            font-size: 16px;
            color: #000;
            font-weight: bold;
          }
        }
      }
      .add-change {
        margin: 10px;
        .scan-input {
          border: 1px solid #ddd;
          i {
            margin: 0 8px;
            font-size: 20px;
          }
          input {
            border: none;
            outline: none;
            background: none;
            width: 220px;
            font-size: 16px;
          }
          span {
            padding: 2px 8px;
            color: #fff;
            background: #0b83f3;
            margin: 8px;
            cursor: pointer;
            border-radius: 2px;
          }
        }
        .choose-add {
          padding: 6px 10px;
          color: #fff;
          background: #0b83f3;
          cursor: pointer;
          border-radius: 2px;
        }
      }
      .table-style {
        margin: 10px;
      }
    }
    .chang-desc {
      p {
        margin: 10px 0;
        span {
          color: red;
        }
      }
    }
    .action-btn {
      margin-top: 20px;
      .reset-goods {
        line-height: 20px;
        width: 220px;
      }
      .sure-change {
        flex: 1;
        line-height: 20px;
      }
    }
  }
}
.activity {
  background: linear-gradient(-90deg, #ec4620, #f08702, #f7d400) !important;
}
.gift {
  background: linear-gradient(0deg, #208aec, #02d7f0, #00c6f7) !important;
}
.add-buy {
  background: linear-gradient(0deg, rgb(116, 47, 169), rgb(101, 41, 147), rgb(72, 30, 105)) !important;
}
</style>
