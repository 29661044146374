<template>
  <el-dialog
    title="客订提示"
    width="60%"
    top="20vh"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    append-to-body
    @close="close"
  >
    <div slot="title">当前客订货品已有预计库存，是否继续走客订</div>
    <section class="content">
      <div class="header row">
        <div
          class="row align-center space-center"
          style="width: 120px; border: 1px solid #eee"
        >
          货品名称
        </div>
        <div
          class="row align-center space-center"
          style="width: 120px; border: 1px solid #eee"
        >
          货号
        </div>
        <div
          class="row align-center space-center"
          style="width: 120px; border: 1px solid #eee"
        >
          颜色
        </div>
        <div class="size-title">
          <div
            class="row align-center"
            v-for="(item, row) in sizeGroup"
            :key="row"
          >
            <span
              v-for="size in item.valueInfoList"
              :key="size.code"
              :style="
                item.valueGroupId === valueGroupId
                  ? 'color:#fff;background: rgb(191,231,250);'
                  : ''
              "
              >{{ size.value }}</span
            >
          </div>
        </div>
        <div
          class="row align-center space-center"
          style="width: 70px; border: 1px solid #eee"
        >
          合计
        </div>
      </div>
      <div class="body" v-loading="loading">
        <div
          class="row"
          v-for="(item, line) in goodsList"
          :key="line"
          :style="{
            background:
              valueGroupId === item.sizeGroupId ? 'rgb(249,250,252)' : '',
          }"
          @click="valueGroupId = item.sizeGroupId"
        >
          <div
            class="row align-center space-center"
            style="width: 120px; border: 1px solid #eee"
          >
            {{ item.goodsName }}
          </div>
          <div
            class="row align-center space-center"
            style="width: 120px; border: 1px solid #eee"
          >
            {{ item.goodsNo }}
          </div>
          <div
            class="row align-center space-center"
            style="width: 120px; border: 1px solid #eee"
          >
            {{ item.color }}
          </div>
          <div class="row align-center" style="flex: 1; border: 1px solid #eee">
            <div style="width: 80px; height: 120px">
              <div
                class="row align-center space-center"
                style="height: 30px; border-bottom: 1px solid #eee"
              >
                可用库存
              </div>
              <div
                class="row align-center space-center"
                style="
                  height: 30px;
                  border-top: 1px solid #eee;
                  border-bottom: 1px solid #eee;
                "
              >
                在途库存
              </div>
              <div
                class="row align-center space-center"
                style="
                  height: 30px;
                  border-bottom: 1px solid #eee;
                  border-top: 1px solid #eee;
                "
              >
                已配未发
              </div>
              <div
                class="row align-center space-center"
                style="height: 30px; border-top: 1px solid #eee"
              >
                预计库存
              </div>
            </div>
            <div class="size-value">
              <div class="row align-center">
                <span
                  style="border-top: none"
                  v-for="(val, x) in item.goodsStockInfoList"
                  :key="x"
                  :style="
                    val.hasColoe &&
                    val.usableQuantity &&
                    +val.usableQuantity > 0
                      ? 'background:#F8475F;color:#fff;'
                      : ''
                  "
                  >{{ val.usableQuantity }}</span
                >
              </div>
              <div class="row align-center">
                <span
                  style="border-bottom: 1px solid #eee"
                  v-for="(val, x) in item.goodsStockInfoList"
                  :key="x"
                  :style="
                    val.hasColoe &&
                    val.inTransitQuantity &&
                    +val.inTransitQuantity > 0
                      ? 'background:#F8475F;color:#fff;'
                      : ''
                  "
                  >{{ val.inTransitQuantity }}</span
                >
              </div>
              <div class="row align-center">
                <span
                  style="border-top: 1px solid #eee"
                  v-for="(val, x) in item.goodsStockInfoList"
                  :key="x"
                  :style="
                    val.hasColoe &&
                    val.notSentQuantity &&
                    +val.notSentQuantity > 0
                      ? 'background:#F8475F;color:#fff;'
                      : ''
                  "
                  >{{ val.notSentQuantity }}</span
                >
              </div>
              <div class="row align-center">
                <span
                  style="border-bottom: none"
                  v-for="(val, x) in item.goodsStockInfoList"
                  :key="x"
                  :style="
                    val.hasColoe &&
                    val.predictQuantity &&
                    +val.predictQuantity > 0
                      ? 'background:#F8475F;color:#fff;'
                      : ''
                  "
                  >{{ val.predictQuantity }}</span
                >
              </div>
            </div>
          </div>
          <div style="width: 70px; border: 1px solid #eee">
            <div
              style="
                height: 30px;
                line-height: 30px;
                border-bottom: 1px solid #eee;
              "
            >
              {{ item.totalOne }}
            </div>
            <div
              style="
                height: 30px;
                line-height: 30px;
                border-top: 1px solid #eee;
                border-bottom: 1px solid #eee;
              "
            >
              {{ item.totalTwo }}
            </div>
            <div
              style="
                height: 30px;
                line-height: 30px;
                border-bottom: 1px solid #eee;
                border-top: 1px solid #eee;
              "
            >
              {{ item.totalThree }}
            </div>
            <div
              style="
                height: 30px;
                line-height: 30px;
                border-top: 1px solid #eee;
              "
            >
              {{ item.totalFour }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <span slot="footer">
      <el-button size="small" @click="close()">取 消</el-button>
      <el-button size="small" type="primary" @click="close('refresh')"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { erpQuerySizeInfo } from "@/libs/http/api";
import { queryMarketing } from "@/libs/http/modules/customer";
export default {
  name: "KedingTips",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      dialogVisible: true,
      goodsList: [],
      sizeGroup: [],
      valueGroupId: null,
    };
  },
  created() {
    this.queryList();
  },
  methods: {
    close(e) {
      if (e === "refresh") {
        this.$emit("close", e);
      } else {
        this.$emit("close");
      }
      this.dialogVisible = false;
    },
    queryList() {
      this.loading = true;
      queryMarketing({
        goodsNoList: this.list.map((item) => {
          return item.goodsNo;
        }),
      })
        .then((data) => {
          this.loading = false;
          data.goodsInfoList.forEach((item) => {
            let sizeArr = [];
            let totalOne = item.goodsStockInfoList.reduce((pre, cur) => {
              return Number(pre) + Number(cur.usableQuantity);
            }, 0);
            let totalTwo = item.goodsStockInfoList.reduce((pre, cur) => {
              return Number(pre) + Number(cur.inTransitQuantity);
            }, 0);
            let totalThree = item.goodsStockInfoList.reduce((pre, cur) => {
              return Number(pre) + Number(cur.notSentQuantity);
            }, 0);
            let totalFour = item.goodsStockInfoList.reduce((pre, cur) => {
              return Number(pre) + Number(cur.predictQuantity);
            }, 0);
            this.list.forEach((val) => {
              if (item.goodsNo === val.goodsNo) {
                sizeArr.push({ value: val.attrList[1].value });
              }
            });
            this.$set(item, "totalOne", totalOne);
            this.$set(item, "totalTwo", totalTwo);
            this.$set(item, "totalThree", totalThree);
            this.$set(item, "totalFour", totalFour);
            this.$set(item, "sizeArr", sizeArr);
          });
          this.goodsList = data.goodsInfoList;
          this.querySizeGroup();
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    querySizeGroup() {
      erpQuerySizeInfo({
        valueGroupIds: this.goodsList.map((item) => {
          return item.sizeGroupId;
        }),
      })
        .then((data) => {
          this.sizeGroup = data;
          data.forEach((item) => {
            this.goodsList.forEach((goods) => {
              if (item.valueGroupId === goods.sizeGroupId) {
                item.valueInfoList.forEach((val, idx) => {
                  goods.sizeArr.forEach((size) => {
                    if (val.value === size.value) {
                      goods.goodsStockInfoList[idx].hasColoe = true;
                    }
                  });
                });
              }
            });
          });
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin: -20px 0;
  text-align: center;
  font-size: 16px;
  border: 1px solid #eee;

  .header {
    background: rgb(249, 250, 252);

    .size-title {
      flex: 1;
      border: 1px solid #eee;
      padding: 10px 0;

      div {
        margin-left: 80px;
      }

      span {
        width: 50px;
        background: #fff;
        padding: 3px 0;
        border: 1px solid #eee;
      }
    }
  }

  .body {
    .size-value {
      height: 120px;

      > div {
        flex: 1;

        span {
          width: 50px;
          border: 1px solid #eee;
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
