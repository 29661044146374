<template>
  <el-drawer
    size="80%"
    title="审批详情"
    append-to-body
    :visible="changeVisiable"
    direction="rtl"
    :close-on-press-escape="true"
    :wrapperClosable="false"
    @close="close">
    <div
      v-loading="infoDetailLoading"
      element-loading-text="请稍后，全力加载中..."
      element-loading-spinner="el-icon-loading">
      <div class="audit-info">
        <div class="info-top row align-center">
          <div class="info-line"></div>
          <div class="info-title">
            {{
              reviewInfo.queryFlowType === '3'
                ? '改价申请单'
                : reviewInfo.queryFlowType === '5'
                ? '次品申请单'
                : reviewInfo.queryFlowType === '6'
                ? '作废申请单'
                : ''
            }}
          </div>
        </div>
        <div class="info-list">
          <img
            class="info-img"
            v-if="infoList.state || infoList.state === '0'"
            :src="reviewInfo.queryFlowType === '6' ? voidState[infoList.state] : invoiceState[infoList.state]" />
          <el-row>
            <el-col :span="8">
              <div class="row align-center">
                <div class="list-lable">提交人</div>
                <div class="list-value">{{ infoList.submitUserName }}</div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="row align-center">
                <div class="list-lable">提交时间</div>
                <div class="list-value">{{ infoList.createTime }}</div>
              </div>
            </el-col>
            <!-- <el-col :span="8">
              <div class="row align-center">
                <div class="list-lable">审批编号</div>
                <div class="list-value">{{ infoList.reviewNo }}</div>
              </div>
            </el-col> -->
            <el-col :span="8">
              <div class="row align-center">
                <div class="list-lable">店铺名称</div>
                <div class="list-value">{{ infoList.deptName }}</div>
              </div>
            </el-col>
            <template v-if="reviewInfo.queryFlowType === '3'">
              <el-col :span="8">
                <div class="row align-center">
                  <div class="list-lable">原价</div>
                  <div class="list-value">{{ infoList.beforePrice }}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="row align-center">
                  <div class="list-lable">改后金额</div>
                  <div class="list-value">{{ infoList.afterPrice }}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="row align-center">
                  <div class="list-lable">变动金额</div>
                  <div class="list-value">
                    {{
                      Number(infoList.beforePrice) - Number(infoList.afterPrice) < 0
                        ? '增加' + -1 * (Number(infoList.beforePrice) - Number(infoList.afterPrice))
                        : '减少' + (Number(infoList.beforePrice) - Number(infoList.afterPrice))
                    }}
                  </div>
                </div>
              </el-col>
              <!-- <el-col :span="8">
                <div class="row align-center">
                  <div class="list-lable">低于原价(%)</div>
                  <div class="list-value">{{ infoList.lessThanPercent }}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="row align-center">
                  <div class="list-lable">低于原价(元)</div>
                  <div class="list-value">{{ infoList.lessThanPrice }}</div>
                </div>
              </el-col> -->
            </template>
          </el-row>
          <el-row>
            <el-col>
              <div class="row align-center">
                <div class="list-lable">备注</div>
                <div class="list-value">{{ infoList.description }}</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div style="height: 10px; background: #f5f5f5; margin: 0"></div>
      <div class="table-card">
        <div class="row align-center">
          <div class="info-line"></div>
          <div class="info-title">
            {{
              reviewInfo.queryFlowType === '3'
                ? '改价商品列表'
                : reviewInfo.queryFlowType === '5'
                ? '次品列表'
                : reviewInfo.queryFlowType === '6'
                ? '作废货品列表'
                : ''
            }}
          </div>
        </div>
        <section style="margin: 20px 16px 0 25px">
          <grid-manager
            v-if="tableData.length"
            :option="
              reviewInfo.queryFlowType === '3'
                ? changePriceOption
                : reviewInfo.queryFlowType === '5'
                ? cipinOption
                : reviewInfo.queryFlowType === '6'
                ? voidOptions
                : otherOptions
            "></grid-manager>
        </section>
      </div>
      <div style="height: 10px; background: #f5f5f5; margin: 0"></div>
      <section>
        <FlowDetail
          v-if="infoList.instanceFlowListResp"
          :instanceFlowListResp="infoList.instanceFlowListResp"></FlowDetail>
      </section>
    </div>
  </el-drawer>
</template>

<script>
import FlowDetail from '@/components/FlowDetail.vue'
import { queryInvoicesInfoOms } from '@/libs/http/api.js'
import { posQueryAlterPriceInfo, postQueryVoidInfo } from '@/libs/http/modules/posOrder'
export default {
  props: ['reviewInfo'],
  components: {
    FlowDetail
  },
  data() {
    return {
      changeVisiable: true,
      invoiceState: {
        '-1': require('@/assets/images/state/ReviewDetails_0.png'),
        3: require('@/assets/images/state/ReviewDetails_1.png'),
        0: require('@/assets/images/state/ReviewDetails_1.png'),
        1: require('@/assets/images/state/ReviewDetails_2.png'),
        2: require('@/assets/images/state/ReviewDetails_3.png'),
        4: require('@/assets/images/state/ReviewDetails_4.png')
      },
      voidState: {
        1: require('@/assets/images/state/ReviewDetails_1.png'),
        2: require('@/assets/images/state/ReviewDetails_2.png'),
        3: require('@/assets/images/state/ReviewDetails_3.png'),
        4: require('@/assets/images/state/ReviewDetails_0.png')
      },
      changePriceOption: {
        gridManagerName: 'changePrice',
        firstLoading: true,
        height: '275px',
        isCombSorting: true,
        supportAutoOrder: false,
        supportCheckbox: false,
        supportConfig: true,
        disableAutoLoading: true,
        supportMenu: false,
        disableBorder: true,
        disableLine: true,
        columnData: [
          {
            key: 'goodsImg',
            text: '货品信息',
            align: 'center',
            width: '280px',
            template: () => {
              return `
                <div class="row align-cneter" style="text-align:left;">
                  <div style="position:relative;overflow:hidden;width:102px;height:92px;border-radius:4px;">
                    <el-image v-if="row.goodsImg && row.goodsImg[0]" style="width:102px;height:92px;border-radius:4px;float:left;" :preview-src-list="row.goodsImg" :src="row.goodsImg[0]"></el-image>
                    <div v-else style="width:102px;height:92px;border-radius:4px;text-align:center;line-height:92px;color:#999;background:#f5f5f5;">暂无图片</div>
                  </div>
                  <div class="column space-center" style="margin-left:10px;flex:1;width:170px;">
                    <div style="font-size:18px;color:#333;margin-top:-4px;">{{row.goodsName}}</div>
                    <div style="font-size:14px;color:#999;margin:4px 0;">货号：{{row.goodsNo}}</div>
                    <div style="font-size:14px;color:#999;">条码：{{row.barCode}}</div>
                  </div>
                </div>
              `
            }
          },
          {
            key: 'attr',
            text: '颜色 / 尺码',
            align: 'center',
            // width: "180px",
            template: () => {
              return `
                <div v-if="row.attrList" class="row space-center align-center" style="width:130px;border:1px solid #DEEEF9;background:#fff;color:#333;line-height:26px;border-radius:2px;cursor:pointer;margin:0 auto">
                  <p v-for="(item, index) in row.attrList.slice(0, 2)" :key="item.code" style="margin-right:10px;">{{item.value}}
                   <span v-if="index == 0" style="margin:0 3px;">,</span>
                  </p>
                </div>
              `
            }
          },
          {
            key: 'tagPrice',
            text: '吊牌价',
            align: 'center',
            template: () => {
              return `
                <div class="row align-cneter space-center" style="font-size:18px">
                  ￥{{row.tagPrice}}
                </div>
              `
            }
          },
          {
            key: 'discount',
            text: '折扣',
            align: 'center',
            template: () => {
              return `
                <div class="row align-cneter space-center" style="font-size:18px">
                  {{(row.unitAfterRealPrice / row.tagPrice * 10).toFixed(1)}}折
                </div>
              `
            }
          },
          {
            key: 'number',
            text: '数量',
            align: 'center',
            // width: "120px",
            template: () => {
              return `
                  <div class="row align-center space-center" style="font-size:18px">
                    {{row.number}}
                  </div>
                `
            }
          },
          {
            key: 'oldPrice',
            text: '原单卖价',
            align: 'center',
            // width: "120px",
            template: () => {
              return `
                <div class="row align-center space-center" style="font-size:18px;">
                  <span>￥</span>{{row.oldPrice}}
                </div>
              `
            }
          },
          {
            key: 'unitRealPrice',
            text: '改前单价',
            align: 'center',
            // width: "120px",
            template: () => {
              return `
                <div class="row align-center space-center" style="font-size:18px;">
                  <span>￥</span>{{row.unitRealPrice}}
                </div>
              `
            }
          },
          {
            key: 'unitAfterRealPrice',
            text: '改后单价',
            align: 'center',
            width: '130px',
            template: () => {
              return `
                <div class="row align-center space-center" style="font-size:18px;">
                  <div v-if="row.isNotAll" class="row align-center space-center">
                    <span style="color:#666;margin-right:8px;">￥</span>
                    <input disabled v-model="row.unitAfterRealPrice" style="border:1px solid #eee;outline:none;background:#FAFAFA;width:90px;text-align:center;height:46px;font-size:20px;border-radius:2px;" />
                  </div>
                  <p v-else class="row align-center space-center">
                    <span>￥</span>
                    <span style="border:1px solid #eee;background:#FAFAFA;width:90px;line-height:46px;">{{row.unitAfterRealPrice}}</span>
                  </p>
                </div>
              `
            }
          },
          {
            key: 'totalRealPrice',
            text: '改前总价',
            align: 'center',
            // width: "130px",
            template: () => {
              return `
                <div class="row align-cneter space-center" style="color:#F8475F;font-size:20px;">
                  ￥{{(row.unitRealPrice * row.number).toFixed(2)}}
                </div>
              `
            }
          },
          {
            key: 'allUnitAfterRealPrice',
            text: '改后总价',
            align: 'center',
            // width: "130px",
            template: () => {
              return `
                <div class="row align-cneter space-center" style="color:#F8475F;font-size:20px;">
                  ￥{{(row.unitAfterRealPrice * row.number).toFixed(2)}}
                </div>
              `
            }
          }
        ],
        ajaxData: this.showGoodsTable
      },
      cipinOption: {
        gridManagerName: 'cipinlist',
        firstLoading: true,
        height: 'auto',
        isCombSorting: true,
        supportAutoOrder: false,
        supportCheckbox: false,
        supportMenu: false,
        supportConfig: true,
        disableAutoLoading: true,
        disableBorder: false,
        disableLine: false,
        columnData: [
          {
            key: 'barCode',
            text: '条码',
            align: 'center'
          },
          {
            key: 'goodsNo',
            text: '货号',
            align: 'center'
          },
          {
            key: 'goodsName',
            text: '货品名称',
            align: 'center'
          },
          {
            key: 'number',
            text: '数量',
            align: 'center',
            template: () => {
              return `
                <div class="row align-center space-center">
                  <input v-if="row.isApprove" :placeholder="row.number || '0'" v-model="row.number" style="border:1px solid #ddd;line-height:30px;width:100px;outline:none;text-align:center;color:#666;" @focus="getDefaultVal(row.number)" @change="setNewNumber(row, index)" />
                  <span v-else>{{row.number}}</span>
                </div>
              `
            }
          },
          {
            key: 'suggestPrice',
            text: '建议售价(单价)',
            align: 'center',
            template: () => {
              return `
                <div class="row align-center space-center">
                  <input v-if="row.isApprove" :placeholder="row.suggestPrice || '0.00'" v-model="row.suggestPrice" style="border:1px solid #ddd;line-height:30px;width:100px;outline:none;text-align:center;color:#666;" @focus="getDefaultVal(row.suggestPrice)" @change="setNewPrice(row, index)" />
                  <span v-else>{{row.suggestPrice}}</span>
                </div>
              `
            }
          },
          {
            key: 'tagPrice',
            text: '吊牌价',
            align: 'center'
          },
          {
            key: 'goodsImages',
            text: '货品图片',
            align: 'center',
            template: () => {
              return `
                <div>
                  <el-image v-if="row.goodsImages && row.goodsImages.length" src="http://soko.upload.babiboy.com/group1/M00/29/58/Cs4ABWMJzF6AGDc5AAACnSHXppY109.png" style="width: 42px;height: 12px;" :preview-src-list="row.goodsImages"></el-image>
                  <span v-else>未上传图片</span>
                </div>
              `
            }
          },
          {
            key: 'tagImages',
            text: '吊牌图片',
            align: 'center',
            template: () => {
              return `
                <div>
                  <el-image v-if="row.tagImages && row.tagImages.length" src="http://soko.upload.babiboy.com/group1/M00/29/58/Cs4ABWMJzF6AGDc5AAACnSHXppY109.png" style="width: 42px;height: 12px;" :preview-src-list="row.tagImages"></el-image>
                  <span v-else>未上传图片</span>
                </div>
              `
            }
          }
        ],
        ajaxData: this.showGoodsTable
      },
      voidOptions: {
        gridManagerName: 'voidList',
        firstLoading: true,
        height: '275px',
        isCombSorting: true,
        supportAutoOrder: false,
        supportCheckbox: false,
        supportMenu: false,
        supportConfig: true,
        disableAutoLoading: true,
        disableBorder: true,
        disableLine: true,
        columnData: [
          {
            key: 'goodsName',
            text: '货品信息',
            align: 'center',
            width: '350px',
            template: () => {
              return `
                <div class="row align-cneter" style="text-align:left;">
                  <div style="position:relative;overflow:hidden;width:102px;height:92px;border-radius:4px;">
                    <el-image v-if="row.goodsImg && row.goodsImg[0]" style="width:102px;height:92px;border-radius:4px;float:left;" :preview-src-list="row.goodsImg" :src="row.goodsImg[0]"></el-image>
                    <div v-else style="width:102px;height:92px;border-radius:4px;text-align:center;line-height:92px;color:#999;background:#f5f5f5;">暂无图片</div>
                    <div v-if="row.activeId"
                      style="
                      width:70px;height:22px;line-height:22px;position:absolute;top:4px;right:-19px;transform: rotate(45deg);text-align:center;
                      background: linear-gradient(-90deg, #EC4620, #F08702, #F7D400);color:#fff;font-size:12px;">
                      {{'促销'}}
                    </div>
                  </div>
                  <div class="column space-center" style="margin-left:10px;flex:1;width:170px;">
                    <div style="font-size:18px;color:#333;margin-top:-4px;">{{row.goodsName}}</div>
                    <div style="font-size:14px;color:#999;margin:4px 0;">货号：{{row.goodsNo}}</div>
                    <div style="font-size:14px;color:#999;">条码：{{row.barCode}}</div>
                  </div>
                </div>
              `
            }
          },
          {
            key: 'colorDesc',
            text: '颜色 / 尺码',
            align: 'center',
            // width: "150px",
            template: () => {
              return `
              <div v-if="row.attrList" class="row space-center align-center text-center" style="border:1px solid #DEEEF9;background:#fff;color:#333;line-height:26px;border-radius:2px;cursor:pointer;">
                <p v-for="(item, index) in row.attrList.slice(0,2)" :key="item.code" style="">{{item.value}}
                  <span v-if="index == 0" style="margin:0 3px;">,</span>
                </p>
              </div>
            `
            }
          },
          {
            key: 'unitRealPrice',
            text: '实卖价(单价)',
            align: 'center',
            // width: "200px",
            template: () => {
              return `
                <div class="row align-center space-center">
                  <span style="color:#666;font-size:20px;">￥{{row.unitRealPrice}}</span>
                </div>
              `
            }
          },
          {
            key: 'tagPrice',
            text: '吊牌价',
            align: 'center',
            template: () => {
              return `
                <div class="row align-center space-center">
                  <span style="font-size:20px;">￥{{row.tagPrice}}</span>
                </div>
              `
            }
          },
          {
            key: 'totalRealPrice',
            text: '金额(元)',
            align: 'center',
            // width: "150px",
            template: () => {
              return `
                        <div class="row align-cneter space-center" style="color:#F8475F;font-size:20px;">
                        ￥{{row.totalRealPrice}}
                        </div>
                    `
            }
          },
          {
            key: 'number',
            text: '销售件数',
            align: 'center',
            // width: "100px",
            template: () => {
              return `
                      <div class="row align-cneter space-center" style="color:#333333;font-size:20px;">
                        {{row.number || 0}}
                        </div>
                    `
            }
          }
        ],
        ajaxData: this.showGoodsTable
      },
      otherOptions: {
        gridManagerName: 'otherList'
      },
      tableData: [],
      infoList: {},
      infoDetailLoading: false
    }
  },
  created() {
    if (this.reviewInfo.queryFlowType === '3') {
      this.fetchPrice()
    } else if (this.reviewInfo.queryFlowType === '6') {
      this.fetchVoid()
    } else {
      this.queryInvoicesInfo()
    }
  },
  methods: {
    fetchPrice() {
      this.infoDetailLoading = true
      posQueryAlterPriceInfo({
        id: this.reviewInfo.id
      })
        .then(data => {
          this.infoList = data
          this.tableData = data.goodsInfos || []
          this.infoDetailLoading = false
          GridManager.refreshGrid(this.changePriceOption.gridManagerName)
        })
        .catch(error => {
          this.$message({ showClose: true, message: error, type: 'error', duration: 2000 })
          this.infoDetailLoading = false
        })
    },
    fetchVoid() {
      this.infoDetailLoading = true
      postQueryVoidInfo({
        id: this.reviewInfo.id
      })
        .then(data => {
          this.infoList = data
          this.tableData = data.goodsInfos || []
          this.infoDetailLoading = false
          GridManager.refreshGrid(this.voidOptions.gridManagerName)
        })
        .catch(error => {
          this.$message({ showClose: true, message: error, type: 'error', duration: 2000 })
          this.infoDetailLoading = false
        })
    },

    queryInvoicesInfo() {
      this.infoDetailLoading = true
      let reqData = {
        reviewId: this.reviewInfo.id,
        hrFlowTypeId: this.reviewInfo.hrFlowTypeId,
        instanceInfoId: this.reviewInfo.instanceInfoId
      }
      queryInvoicesInfoOms(reqData)
        .then(data => {
          this.infoList = data
          this.tableData = data.goodsInfos || []
          if (data.reviewType === '5') {
            this.reviewInfo.queryFlowType = '5'
            let list = [
              {
                barCode: data.barCode,
                goodsNo: data.goodsNo,
                goodsName: data.goodsName,
                number: data.number,
                tagPrice: data.tagPrice,
                suggestPrice: data.suggestPrice,
                goodsImages: data.goodsImages ? data.goodsImages.split(',') : [],
                tagImages: data.tagImages ? data.tagImages.split(',') : []
              }
            ]
            this.tableData = list
            GridManager.refreshGrid(this.cipinOption.gridManagerName)
          } else {
          }
          setTimeout(() => {
            this.infoDetailLoading = false
          }, 500)
        })
        .catch(err => {
          this.$message({ showClose: true, message: err, type: 'error', duration: 2000 })
          this.infoDetailLoading = false
        })
    },

    showGoodsTable() {
      if (this.tableData.length) {
        return new Promise((resolve, reject) => {
          const tableData = {
            data: this.tableData
          }
          resolve(tableData)
        })
      }
    },
    close() {
      this.changeVisiable = false
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.table-card {
  padding: 20px 0;
}
.mar-top {
  margin-top: 5px;
}
.info-line {
  width: 4px;
  height: 24px;
  background: #067cf2;
  margin-right: 20px;
}
.info-title {
  font-size: 18px;
  color: #333333;
}
.audit-info {
  padding: 0 0 25px;
  .info-list {
    padding: 10px 25px 0;
    position: relative;
    .info-img {
      position: absolute;
      width: 84px;
      height: 74px;
      right: 109px;
      top: -15px;
      z-index: 9;
    }
    .list-lable {
      width: 100px;
      color: #999999;
      text-align: left;
      margin-bottom: 10px;
    }
    .list-value {
      color: #333333;
      margin-bottom: 10px;
    }
  }
}
</style>
