<template>
	<dialogModule
		top="18"
		width="750px"
		v-if="attrVisible"
		:title="dialogTitle"
		:dialogVisible="attrVisible"
		@close="close">
    	<span slot="close" class="el-icon-close close-icon" @click="close"></span>
		<div class="attr-box" slot="content">
			<div class="row" v-if="goodsInfo">
				<el-image v-if="goodsInfo.imageList && goodsInfo.imageList[0]" class="goods-img" :src="goodsInfo.imageList[0]" :preview-src-list="goodsInfo.imageList"></el-image>
				<div v-else class="row align-center space-center goods-img">暂无图片</div>
				<div class="goods-other">
					<p>{{goodsInfo.title}}</p>
					<div class="goods-no row align-center">
						<span>货号：{{goodsInfo.goodsNo}}</span>
						<template v-if="isColorSize">
							<span>条码：{{barCode}}</span>
						</template>
					</div>
					<div class="row color-size" v-if="goodsInfo.attrList">
						<i>颜色：</i>
						<div class="row flex-wrap">
							<span
								v-for="(val, c) in goodsInfo.attrList[1].valueList"
								:key="c"
								:class="[colorVal === val.value ? 'selected-span' : '']"
								@click="selectedColor(val)">
								{{val.value}}
							</span>
						</div>
					</div>
					<div class="row color-size" v-if="goodsInfo.attrList">
						<i>尺码：</i>
						<div class="row flex-wrap">
							<span
								v-for="(val, c) in goodsInfo.attrList[0].valueList"
								:key="c"
								:class="[sizeVal === val.value ? 'selected-span' : '']"
								@click="selectedSize(val)">
								{{val.value}}
							</span>
						</div>
					</div>
					<div class="stock-number row align-center">
						<div class="row align-center stock">
							<i>库存：</i>
							<span>{{selectGoods.stock || '0'}}</span>
							件
						</div>
						<div class="row align-center number-action">
							<span class="row align-center space-center" @click="minusNum">
								<i class="el-icon-minus"></i>
							</span>
							<input type="text" v-model="number" @input="number=number.replace(/^0|[^0-9]/g,'1')">
							<span class="row align-center space-center" @click="plusNum">
								<i class="el-icon-plus"></i>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div class="handel-btn row space-center align-center">
				<div class="cancel" @click="close">取消</div>
				<div class="sure" @click="sureHandel">确定</div>
			</div>
		</div>
	</dialogModule>
</template>

<script>
import dialogModule from "@/components/dialogModule.vue";
import {queryStoreInfoList} from "@/libs/http/modules/posOrder";
export default {
	name: "attrDialog",
	props: {
		barCode: {
			type: String,
			default: () => ''
		},
		maxNumber: {
			type: Number,
			default: () => 0
		},
		keyword: {
			type: String,
			default: () => ''
		},
		isColorSize: {
			type: Boolean,
			default: false
		},
		isAllowNegativeStocks: {
			type: String,
			default: () => ''
		}
	},
	components: {
		dialogModule,
	},
	data() {
		return {
			attrVisible: true,
			number: '1',
			goodsInfo: {},
			selectGoods: {},
			colorVal: '',
			sizeVal: ''
		};
	},
	computed: {
		dialogTitle(){
			if(this.isColorSize){
				return `选择换货色码`
			}else{
				return `选择颜色尺码`
			}
		}
	},
	created() {
		this.fetchGoods()
	},
	methods: {
		close() {
			this.attrVisible = false;
			this.$emit("close");
		},
		fetchGoods(){
			let reqData = {}
			if(this.isColorSize){
				reqData['skuBarCode'] = this.barCode
			}else{
				reqData['goodsNo'] = this.keyword
			}
			queryStoreInfoList(reqData).then(res=>{
				this.goodsInfo = res.spuInfoBean
			}).catch(error=>{
				this.$message({showClose: true, message: error, type: 'error', duration: 2000})
			})
		},
		minusNum(){
			if(Number(this.number) <= 1){
				return this.$message({showClose: true, message: '最小数量为1，不能再减了', type: 'error', duration: 2000})
			}
			this.number--
		},
		plusNum(){
			this.number++
		},
		selectedColor(val){
			this.colorVal = val.value
			if(this.sizeVal){
				this.goodsInfo.storeSkuInfoBeanList.forEach(item=>{
					if(item.attrList[0].value === this.colorVal && item.attrList[1].value === this.sizeVal){
						this.selectGoods = item
					}
				})
			}
		},
		selectedSize(val){
			this.sizeVal = val.value
			if(this.colorVal){
				this.goodsInfo.storeSkuInfoBeanList.forEach(item=>{
					if(item.attrList[0].value === this.colorVal && item.attrList[1].value === this.sizeVal){
						this.selectGoods = item
					}
				})
			}
		},
		sureHandel(){
			if(this.isAllowNegativeStocks !== 'Y' && Number(this.selectGoods.stock) <= 0){
				return this.$message({showClose: true, message: '当前货品库存不足，请选择其他货品', type: 'error', duration: 2000})
			}
			if(!this.selectGoods.barCode){
				return this.$message({showClose: true, message: '请选择要换的颜色尺码', type: 'error', duration: 2000})
			}
			if(this.barCode === this.selectGoods.barCode){
				return this.$message({showClose: true, message: '不能换相同颜色和尺码的货品', type: 'error', duration: 2000})
			}
			if(Number(this.number) > this.maxNumber){
				return this.$message({showClose: true, message: `当前最大可换货数量为${this.maxNumber}`, type: 'error', duration: 2000})
			}
			let obj = {
				number: this.number,
				barCode: this.selectGoods.barCode
			}
			this.$emit('sure', obj)
			this.attrVisible = false
		}
	}
}
</script>

<style lang="scss" scoped>
.close-icon{
	color: #999;
	padding: 10px;
	cursor: pointer;
	margin-right: -10px;
}
.attr-box{
	padding: 15px;
	.goods-img{
		width: 200px;
		height: 200px;
		color: #999;
		font-size: 14px;
		background: #eee;
		margin-right: 15px;
		border-radius: 4px;
	}
	.goods-other{
		flex: 1;
		p{
			font-size: 18px;
			color: #000;
			margin-bottom: 10px;
		}
		.goods-no{
			font-size: 14px;
			color: #666;
			padding-bottom: 15px;
			margin-bottom: 15px;
			border-bottom: 1px solid #eee;
			span{
				margin-right: 10px;
			}
		}
		.color-size{
			margin-bottom: 10px;
			i{
				font-style: normal;
				width: 50px;
				margin-top: 5px;
			}
			div{
				flex: 1;
				span{
					width: 70px;
					text-align: center;
					height: 30px;
					font-size: 14px;
					line-height: 30px;
					border-radius: 4px;
					margin-right: 10px;
					margin-bottom: 10px;
					border: 1px solid #ddd;
					cursor: pointer;
				}
				.selected-span{
					border: 1px solid #0b83f3;
					background: rgb(246,252,255);
				}
			}
		}
		.stock-number{
			font-size: 14px;
			margin-top: 20px;
			.stock{
				i{
					width: 50px;
					font-style: normal;
				}
				span{
					color: red;
					margin-right: 4px;
				}
			}
			.number-action{
				margin-left: 30px;
				cursor: pointer;
				span{
					width: 30px;
					height: 30px;
					background: #0b83f3;
					color: #fff;
					i{
						font-size: 20px;
					}
				}
				input{
					width: 60px;
					height: 30px;
					line-height: 30px;
					outline: none;
					border: 1px solid #ddd;
					background: #fff;
					text-align: center;
					font-size: 16px;
				}
			}
		}
	}
	.handel-btn{
		div{
			width: 120px;
			text-align: center;
			line-height: 40px;
			height: 40px;
			border-radius: 4px;
			cursor: pointer;
			font-size: 16px;
			margin: 80px 0 15px;
		}
		.cancel{
			border: 1px solid #ddd;
		}
		.sure{
			background: #0b83f3;
			color: #fff;
			margin-left: 10px;
		}
	}
}
</style>