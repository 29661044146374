<template>
	<dialogModule
		width="500px"
		title="取消订单"
		v-if="salesVisiable"
		:dialogVisible="salesVisiable"
		top="25"
		@close="close"
	>
    <span
		slot="close"
		class="el-icon-close"
		style="color: #999; padding: 10px; margin-right: -10px; cursor: pointer"
		@click="close"
	></span>
		<div slot="content">
			<div class="cen-title" v-if="receivedAmount != 0">
				订单已完成部分支付：<span style="color: #f8475f"
			>￥{{ receivedAmount }}</span
			>
			</div>
			<div class="cen-title" v-else>订单未完成支付</div>
			<div
				style="
          font-size: 18px;
          color: #999;
          margin: 20px auto 80px;
          text-align: center;
        "
			>
				你的订单还未完成支付，是否要取消
			</div>
			<div class="btn-group row align-center space-center">
        <el-button class="cancel-btn" @click="close">取 消</el-button>
        <el-button class="cancel-btn" type="primary" :loading="submitLoading" @click="submit">确 认</el-button>
			</div>
		</div>
	</dialogModule>
</template>

<script>
import dialogModule from "@/components/dialogModule.vue";
import {cancelOrdingState} from "@/libs/http/modules/posOrder";

export default {
	props: ["receivedAmount", "ordingId"],
	components: {
		dialogModule,
	},
	data() {
		return {
			salesVisiable: false,
      submitLoading: false
		};
	},
	created() {
		this.salesVisiable = true;
	},
	methods: {
		close() {
			this.salesVisiable = false;
			this.$emit("close");
		},
		submit() {
      this.submitLoading = true
			const cashierItem = JSON.parse(this.$cache.local.get('cashierDeskItem'))
			cancelOrdingState({
				orderId: this.ordingId,
				terminalId: cashierItem ? cashierItem.id : ''
			})
				.then(data => {
					this.salesVisiable = false;
					this.$emit("close", 'refresh');
					this.$message({
						showClose: true,
						message: "订单已取消",
						type: "success",
						duration: 2000,
					});
          this.submitLoading = false
				})
				.catch((err) => {
					this.$message({
						showClose: true,
						message: err,
						type: "error",
						duration: 2000,
					});
          this.submitLoading = false
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.cen-title {
	font-size: 24px;
	color: #333;
	margin: 50px auto 0;
	text-align: center;
	font-family: "font-Regular";
}

.btn-group {
	margin: 0 auto;
	padding-bottom: 20px;
	.cancel-btn {
		width: 110px;
		height: 40px;
		border-radius: 4px;
		font-size: 16px;
		text-align: center;
		cursor: pointer;
	}
}
</style>