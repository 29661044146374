<template>
  <dialogModule
    top="3"
    width="90%"
    :title="`${invoicesItem.orderNo}缺货客订`"
    v-if="salesVisible"
    :closeOnPressEscape="false"
    :dialogVisible="salesVisible"
    @close="close"
  >
    <span slot="close" class="el-icon-close close-icon" @click="close"></span>
    <div slot="content" class="return-box">
      <div
        style="
          position: absolute;
          left: 30%;
          top: -30px;
          color: red;
          font-size: 16px;
          font-weight: bold;
        "
      >
        <!-- 提示：<span>{{ otherInfo.isWeek === 'Y' ? '当前7天内，原价退' : '当前超过7天，默认现价退' }}</span> -->
        <!-- <span>默认按现价退，有特殊情况时可以改价审批</span> -->
      </div>
      <section
        class="row"
        v-loading="fetchLoading"
        element-loading-text="请稍后，全力加载中..."
        element-loading-spinner="el-icon-loading"
      >
        <div class="return-table">
          <grid-manager :option="gridOption"></grid-manager>
        </div>
        <div class="info-right">
          <div
            class="cloumn"
            style="
              border: 1px solid #3333;
              padding: 20px 20px;
              border-radius: 3px;
            "
          >
            <div style="margin-bottom: 10px">
              每天至多可申请<span style="color: red">{{
                information.applyLimit
              }}</span
              >次客订，今日已申请<span style="color: red">{{
                information.todayApplyLimit
              }}</span
              >次
            </div>
            <div>
              每单至多申请<span style="color: red">{{
                information.singleOrderNumber
              }}</span
              >件
            </div>
          </div>
          <div style="margin: 10px 0px">
            <el-checkbox
              v-model="checked1"
              label="客户要货"
              border
              size="medium"
              @change="checkboxChange(1)"
              style="
                width: 180px;
                height: 50px;
                color: #000;
                padding: 14px 10px 0px 14px;
              "
            ></el-checkbox>
            <el-checkbox
              v-model="checked2"
              label="店铺要货"
              border
              size="medium"
              @change="checkboxChange(2)"
              style="
                width: 180px;
                height: 50px;
                color: #000;
                padding: 14px 10px 0px 14px;
              "
            ></el-checkbox>
          </div>
          <div class="mail-box-item" v-if="checked1">
            <div
              class="row align-center space-between item-top"
              style="
                background: linear-gradient(
                  to right,
                  rgb(255, 241, 240),
                  rgb(249, 253, 255),
                  rgb(250, 250, 255)
                );
              "
            >
              <div class="row align-center">
                <span class="label">收</span>
                <span>客户收货信息</span>
              </div>
              <span
                style="color: #0b83f3; cursor: pointer"
                @click="addressParams = true"
                ><i class="el-icon-edit"></i> 智能填写</span
              >
            </div>
            <div class="item-form">
              <el-form inline size="small" label-width="80px">
                <el-form-item label="姓名" required>
                  <el-input
                    placeholder="请输入"
                    v-model="receive.receiveName"
                    style="width: 260px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="手机号" required>
                  <el-input
                    placeholder="请输入"
                    v-model="receive.receiveMobile"
                    style="width: 260px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="省市区" required>
                  <el-cascader
                    placeholder="请选择"
                    style="width: 260px"
                    v-model="receive.addressInfo"
                    :props="{ label: 'name', children: 'nodes', value: 'name' }"
                    :options="addressArray"
                    @change="getSelectAddress"
                  >
                  </el-cascader>
                </el-form-item>
                <el-form-item label="详细地址" required>
                  <el-input
                    placeholder="请输入"
                    v-model="receive.receiveAddress"
                    type="textarea"
                    style="width: 260px"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="mail-box-item" v-if="checked2">
            <div
              class="row align-center space-between item-top"
              style="
                background: linear-gradient(
                  to right,
                  rgb(255, 241, 240),
                  rgb(249, 253, 255),
                  rgb(250, 250, 255)
                );
              "
            >
              <div class="row align-center">
                <span class="label">收</span>
                <span>店铺收货信息</span>
              </div>
            </div>
            <div class="item-form">
              <el-form inline size="small" label-width="80px">
                <el-form-item label="姓名" required>
                  <el-input
                    placeholder="请输入"
                    v-model="receiveTwo.receiveName"
                    style="width: 260px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="手机号" required>
                  <el-input
                    placeholder="请输入"
                    v-model="receiveTwo.receiveMobile"
                    style="width: 260px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="省市区" required>
                  <!-- <el-cascader placeholder="请选择" style="width: 260px" v-model="receive.addressInfo"
                    :props="{ label: 'name', children: 'nodes', value: 'name' }" :options="addressArray"
                    @change="getSelectAddress">
                  </el-cascader> -->
                  <el-input
                    disabled
                    placeholder="请选择"
                    :disabled="true"
                    :value="
                      receiveTwo.receiveProvince +
                      receiveTwo.receiveCity +
                      receiveTwo.receiveArea
                    "
                    style="width: 260px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="详细地址" required>
                  <el-input
                    placeholder="请输入"
                    v-model="receiveTwo.receiveAddress"
                    type="textarea"
                    :disabled="true"
                    style="width: 260px"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <el-button
            class="return-btn"
            type="primary"
            :loading="fetchLoading"
            @click="beforeSureSubmit"
            >确定客订申请</el-button
          >
        </div>
      </section>
      <el-dialog
        append-to-body
        width="30%"
        title="智能填写"
        :visible.sync="addressParams"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        @close="addressParams = false"
      >
        <div style="margin-top: -20px"></div>
        <el-input
          type="textarea"
          placeholder="在此处粘贴整段地址，自动识别姓名，手机号码和地址例：张三，16478909988，广东省广州市番禺区明朗创意园"
          v-model="addressData"
          :rows="5"
        >
        </el-input>
        <div slot="footer">
          <el-button size="small" type="primary" @click="onAddressInput"
            >确认</el-button
          >
        </div>
      </el-dialog>

      <keDingTipsTwo
        v-if="tipsLook"
        @close="sendClose"
        :list="hasStockList"
      ></keDingTipsTwo>
    </div>
  </dialogModule>
</template>

<script>
import dialogModule from "@/components/dialogModule.vue";
import keDingTipsTwo from "./KedingTipsTwo.vue";
import AddressParse from "address-parse";
import {
  returnSalesSubmit,
  salesAfterOperate,
  queryPosBookingGoodDetail,
} from "@/libs/http/modules/posOrder";
import {
  queryDeptAddress,
  saveVisitorApply,
  visitorBooking,
  visitorCheckApply,
} from "@/libs/http/modules/customer";
import { queryCityInfoList } from "@/libs/http/api";
export default {
  name: "returnGoods",
  props: {
    invoicesItem: {
      type: Object,
      default: () => null,
    },
  },
  components: {
    dialogModule,
    keDingTipsTwo,
  },
  data() {
    return {
      salesVisible: true,
      fetchLoading: false,
      tipsLook: false,
      receive: {
        receiveMobile: "",
        receiveName: "",
        receiveAddress: "",
        receiveProvince: "",
        receiveCity: "",
        receiveArea: "",
        addressInfo: "",
      },
      receiveTwo: {
        receiveMobile: "",
        receiveName: "",
        receiveAddress: "",
        receiveProvince: "",
        receiveCity: "",
        receiveArea: "",
      },
      checked1: true,
      checked2: false,
      gridOption: {
        gridManagerName: "return-goods-table1",
        firstLoading: true,
        height: "85vh",
        isCombSorting: true,
        supportAutoOrder: false,
        supportCheckbox: true,
        supportMenu: false,
        supportConfig: true,
        disableAutoLoading: true,
        disableBorder: false,
        disableLine: true,
        checkboxConfig: {
          width: 50,
          // useRowCheck:true,
        },
        columnData: [
          {
            key: "goodsInfo",
            text: "货品信息",
            align: "center",
            width: "300px",
            template: () => {
              return `
								<div class="row" style="text-align:left;">
									<div style="width:102px;height:92px;margin-right:10px;position: relative;overflow: hidden;">
										<el-image v-if="row.imageList && row.imageList.length" style="width:102px;height:92px;border-radius:4px;" :preview-src-list="row.imageList" :src="row.imageList[0]"></el-image>
								    	<div v-else class="row align-center space-center" style="background: #eee;width:102px;height:92px;border-radius:4px;color: #999;">暂无图片</div>
								    </div>
								    <div class="column space-between" style="height:90px;width: calc(100% - 112px);">
										<div style="font-size:18px;color:#333;">{{row.goodsName}}</div>
										<div style="font-size:14px;color:#999;">货号：{{row.goodsNo}}</div>
										<div style="font-size:14px;color:#999;">条码：{{row.barCode}}</div>
								    </div>
								</div>
							`;
            },
          },
          {
            key: "attr",
            text: "颜色 / 尺码",
            align: "center",
            width: "160px",
            template: () => {
              return `
								<div v-if="row.attrList" class="row space-center align-center text-center" style="border:1px solid #DEEEF9;background:#fff;line-height:34px;border-radius:2px;">
									<p v-for="(item, index) in row.attrList" :key="item.code">{{item.value}}
									  	<span v-if="index == 0" style="margin:0 3px;">,</span>
									</p>
							  	</div>
                    		`;
            },
          },
          {
            key: "tagPrice",
            text: "吊牌价",
            align: "center",
            width: "160px",
            template: () => {
              return `
								<div style="font-size: 16px;">￥{{row.tagPrice}}</div>
						    `;
            },
          },
          {
            key: "number",
            text: "单据数量",
            align: "center",
            template: () => {
              return `
								<div style="font-size: 16px;">{{row.number}}</div>
							`;
            },
          },
          {
            key: "bookingQuantity",
            text: "客订申请数量",
            align: "center",
            width: "150px",
            template: () => {
              return `
								<div class="row align-center space-center">
									<input
									type="text"
                  :disabled="row.falg"
									v-model="row.bookingQuantity"
									@change="blurHandel(row, index)"
									@input="row.bookingQuantity=row.bookingQuantity.replace(/^0|[^0-9]/g,'')"
									style="width:80px;height:40px;line-height:40px;text-align:center;border:1px solid #eee;color:#333;outline:none;font-size:18px;" />
							    </div>
							`;
            },
          },
        ],
        ajaxData: this.showGoodsTable,
        checkedAfter: this.checkedAfter,
        // checkedAllAfter: this.checkedAllAfter
      },
      activeObject: null,
      tableData: [],
      goodInfos: [],
      description: "",
      reduceResult: {},
      otherInfo: {},
      activeList: [],
      approveList: [],
      approveInfo: {},
      hrFlowTypeId: "",
      hasAlterPriceInfo: "",
      isAllowNegativeStocks: "",
      addressData: "",
      addressArray: [],
      information: {},
      addressParams: false,
      reqList: [],
      applyNum: 0,
    };
  },
  created() {
    let operateGoodInfo = {
      alterGoodsWay: "4",
    };
    this.operateList(operateGoodInfo);
    // this.fetchActive()
    this.getAddressData();
    this.queryData();
  },
  methods: {
    sendClose(e) {
      this.tipsLook = false;
    },
    queryData() {
      queryDeptAddress({
        id: this.$cache.local.get("deptId"),
      })
        .then((data) => {
          if (data) {
            this.receiveTwo.receiveProvince = data.province;
            this.receiveTwo.receiveCity = data.city;
            this.receiveTwo.receiveArea = data.area;
            this.receiveTwo.receiveAddress = data.address;
            this.receiveTwo.receiveMobile = data.principalPhone;
            this.receiveTwo.receiveName = data.principal;
          }
        })
        .catch((err) => {});
    },
    checkboxChange(type) {
      if (type == "1") {
        this.checked1 = true;
        this.checked2 = false;
      } else {
        this.checked1 = false;
        this.checked2 = true;
      }
    },
    getAddressData() {
      queryCityInfoList()
        .then((data) => {
          this.addressArray = data?.nodes;
        })
        .catch((err) => {});
    },
    onAddressInput() {
      if (!this.addressData) return;
      const parse = AddressParse.parse(this.addressData);
      if (parse && parse.length > 0) {
        const content = parse[0];
        this.receive.receiveProvince = content.province;
        this.receive.receiveCity = content.city;
        this.receive.receiveArea = content.area;
        this.receive.receiveName = content.name;
        this.receive.receiveMobile = content.mobile;
        this.receive.receiveAddress = content.details;
        this.receive.addressInfo = [
          content.province,
          content.city,
          content.area,
        ];
        this.addressParams = false;
      }
    },
    close() {
      this.salesVisible = false;
      this.$emit("close");
    },
    getSelectAddress(e) {
      this.receive.receiveProvince = e[0];
      this.receive.receiveCity = e[1];
      this.receive.receiveArea = e[2];
    },
    showGoodsTable() {
      return new Promise((resolve, reject) => {
        const tableData = {
          data: this.tableData,
        };
        resolve(tableData);
      });
    },
    fetchActive() {
      queryPosBookingGoodDetail({
        id: this.invoicesItem.id,
      })
        .then((res) => {
          if (res) {
            this.otherInfo = {
              isWeek: res.isWeek,
              number: res.number,
              innerSn: res.innerSn,
              isPublic: res.isPublic,
              crmPhone: res.crmPhone,
              userName: res.userName,
              crmUserName: res.crmUserName,
              newOrderPrice: res.newOrderPrice,
            };
            res.activityOrderDetailPojoList.forEach((item) => {
              if (item.goodInfos && item.goodInfos.length) {
                item.list = item.list.concat(item.goodInfos);
              }
            });
          }
        })
        .catch((error) => {
          this.$message({
            showClose: true,
            message: error,
            type: "error",
            duration: 2000,
          });
        });
    },
    checkedAfter(rowData, rowIndex, colIndex) {
      if (rowData.length) {
        this.tableData.forEach((itemA) => {
          const match = rowData.find(
            (itemB) => itemB.detailId === itemA.detailId
          );
          itemA.falg = match ? false : true;
          GridManager.updateRowData(
            this.gridOption.gridManagerName,
            "detailId",
            itemA
          );
        });
      } else {
        this.tableData.forEach((item) => {
          this.$set(item, "falg", true);
        });
        GridManager.refreshGrid(this.gridOption.gridManagerName);
      }
    },
    setChecked() {
      // const checkList = this.tableData.filter(item => {
      //   return item.isSelect === 'Y'
      // })
      // GridManager.setCheckedData(this.gridOption.gridManagerName, checkList)
      GridManager.refreshGrid(this.gridOption.gridManagerName);
    },
    checkedAllAfter(checkedList, isChecked) {
      let operateGoodInfo = {
        isCheckAll: "Y",
        alterGoodsWay: isChecked ? "1" : "2",
      };
      this.operateList(operateGoodInfo);
    },
    blurHandel(row, idx) {
      if (
        row.bookingQuantity * 1 !== 0 &&
        row.bookingQuantity &&
        Number(row.bookingQuantity) > Number(row.number)
      ) {
        GridManager.renderGrid(this.gridOption.gridManagerName);
        return this.$message({
          showClose: true,
          message: `客订申请数量不能大于单据数量`,
          type: "error",
          duration: 2000,
        });
      } else if (!row.bookingQuantity) {
        this.tableData.forEach((itemA) => {
          if (itemA.detailId === row.detailId) {
            itemA.bookingQuantity = 0;
            GridManager.updateRowData(
              this.gridOption.gridManagerName,
              "detailId",
              itemA
            );
          }
        });
      } else {
        this.tableData.forEach((itemA) => {
          if (itemA.detailId === row.detailId) {
            itemA.bookingQuantity = row.bookingQuantity;
            GridManager.updateRowData(
              this.gridOption.gridManagerName,
              "detailId",
              itemA
            );
          }
        });
      }
    },

    recoverPrice(row, idx) {
      this.$confirm("确认要取消改价", "提示")
        .then((res) => {
          let operateGoodInfo = {
            index: idx,
            alterGoodsWay: "1",
            barCode: row.barCode,
            isNoUpdatePrice: "Y",
          };
          this.operateList(operateGoodInfo);
        })
        .catch(() => {});
    },

    // 查询列表信息
    operateList(operateGoodInfo) {
      this.fetchLoading = true;
      let reqData = {
        id: this.invoicesItem?.id,
      };
      queryPosBookingGoodDetail(reqData)
        .then((res) => {
          res.storeInfo.forEach((item) => {
            item.falg = true;
          });
          this.tableData = res.storeInfo;
          GridManager.refreshGrid(this.gridOption.gridManagerName);
          this.information = res;
          this.fetchLoading = false;
        })
        .catch((error) => {
          GridManager.renderGrid(this.gridOption.gridManagerName);
          // this.setChecked()
          this.fetchLoading = false;
          this.$message({
            showClose: true,
            message: error,
            type: "error",
            duration: 2000,
          });
        });
    },
    sendClose(e) {
      this.tipsLook = false;
      if (e) {
        this.sureSubmit(this.reqList);
      }
    },
    beforeSureSubmit() {
      const list = GridManager.getCheckedData(this.gridOption.gridManagerName);
      let messageFalg = false;
      let goodsNolist = [];
      let applyGoodsInfoList = [];
      if (!list.length) {
        return this.$message({
          showClose: true,
          message: "请勾选需要客订的货品",
          type: "error",
          duration: 2000,
        });
      }
      list.forEach((item) => {
        if (item.bookingQuantity * 1 < 1) {
          messageFalg = true;
          this.$message({
            showClose: true,
            message: `已勾选的货品客订申请数量不能为0`,
            type: "error",
            duration: 2000,
          });
        } else if (item.usableQuantity > 0) {
          goodsNolist.push(item);
        }
      });
      if (messageFalg) {
        return;
      }
      let applyNum = 0;
      list.map((item) => {
        applyNum = applyNum + item.bookingQuantity * 1;
        applyGoodsInfoList.push({
          goodsNo: item.goodsNo,
          sku: item.sku,
          number: item.bookingQuantity,
          price: item.unitRealPrice * item.bookingQuantity,
        });
      });
      if (applyNum > this.information.singleOrderNumber) {
        return this.$message({
          showClose: true,
          message: `本次申请数量不能大于每单至多申请数量`,
          type: "error",
          duration: 2000,
        });
      }
      if (this.information.todayApplyLimit > this.information.applyLimit) {
        return this.$message({
          showClose: true,
          message: `今日申请数量不能大于每日申请上限`,
          type: "error",
          duration: 2000,
        });
      }
      const secondPopupData = {
        invoicesId: this.invoicesItem.id,
      };
      if (this.checked1) {
        for (let key in this.receive) {
          if (!this.receive[key]) {
            return this.$message({
              showClose: true,
              message: "请完善客户收货信息",
              type: "error",
              duration: 2000,
            });
          }
        }
        secondPopupData.applyType = 0;
        secondPopupData.receiverName = this.receive.receiveName;
        secondPopupData.phone = this.receive.receiveMobile;
        secondPopupData.province = this.receive.receiveProvince;
        secondPopupData.city = this.receive.receiveCity;
        secondPopupData.area = this.receive.receiveArea;
        secondPopupData.address = this.receive.receiveAddress;
      }
      if (this.checked2) {
        for (let key in this.receiveTwo) {
          if (!this.receiveTwo[key]) {
            return this.$message({
              showClose: true,
              message: "请完善店铺收货信息",
              type: "error",
              duration: 2000,
            });
          }
        }
        secondPopupData.applyType = 1;
        secondPopupData.receiverName = this.receiveTwo.receiveName;
        secondPopupData.phone = this.receiveTwo.receiveMobile;
        secondPopupData.province = this.receiveTwo.receiveProvince;
        secondPopupData.city = this.receiveTwo.receiveCity;
        secondPopupData.area = this.receiveTwo.receiveArea;
        secondPopupData.address = this.receiveTwo.receiveAddress;
      }
      secondPopupData.applyGoodsInfoList = applyGoodsInfoList;
      this.fetchLoading = true;
      visitorCheckApply({
        applyGoodsInfoList: applyGoodsInfoList,
        isSellPos: "Y",
      })
        .then((data) => {
          this.fetchLoading = false;
          if (goodsNolist.length) {
            this.tipsLook = true;
            this.hasStockList = goodsNolist;
            this.reqList = secondPopupData;
          } else {
            this.sureSubmit(secondPopupData);
          }
        })
        .catch((err) => {
          this.fetchLoading = false;
          this.$message({
            type: "error",
            message: err,
            duration: 2000,
            showClose: true,
          });
        });
    },

    queryNearlyDept(res) {
      visitorBooking({
        guestBookingOrderNo: res.guestBookingOrderNo,
        isPos: "Y",
      })
        .then((data) => {
          // this.resultData = data;
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err,
            duration: 2000,
            showClose: true,
          });
        });
    },
    sureSubmit(req) {
      this.fetchLoading = true;
      saveVisitorApply(req)
        .then((res) => {
          this.queryNearlyDept(res);
          this.salesVisible = false;
          this.$emit("refresh");
          this.fetchLoading = false;
        })
        .catch((error) => {
          this.fetchLoading = false;
          this.$message({
            showClose: true,
            message: error,
            type: "error",
            duration: 2000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.mail-box-item {
  background: #fff;
  border-radius: 6px;
  border: 1px solid #e8e8e8;

  .item-top {
    height: 40px;
    border-radius: 6px 6px 0 0;
    background: #fafafa;
    padding: 0 10px;

    .label {
      width: 22px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      border-radius: 50%;
      background: #f8475f;
      color: #fff;
      margin-right: 10px;
    }
  }

  .item-form {
    padding: 15px 10px 0;
  }
}

.close-icon {
  color: #999;
  padding: 10px;
  margin-right: -10px;
  cursor: pointer;
}

.return-box {
  padding: 10px;
  position: relative;

  .return-table {
    width: calc(100% - 410px);
  }

  .info-right {
    width: 400px;
    margin-left: 10px;

    .active-list {
      background: #f5f5f5;
      padding: 10px;
      border-radius: 6px;
      margin-bottom: 15px;

      .active-info {
        background: #fff;
        margin-bottom: 10px;
        border-radius: 4px;

        &:last-child {
          margin-bottom: 0;
        }

        p {
          border-bottom: 1px solid #eee;
          line-height: 32px;

          span {
            padding: 0 6px;
            background: rgb(255, 144, 0);
            color: #fff;
            border-radius: 4px;
            margin: 0 10px;
          }

          i {
            font-style: normal;
          }
        }

        .active-goods {
          padding: 8px 10px 2px;

          .goods-relative {
            width: 86px;
            margin-right: 10px;
            position: relative;
            overflow: hidden;

            &:last-child {
              margin-right: 0;
            }

            .goods-image {
              width: 86px;
              height: 80px;
              border-radius: 4px;
              float: left;
              background: #eee;
              color: #999;
            }

            .active-num {
              position: absolute;
              left: 0;
              bottom: 19px;
              font-size: 10px;
              color: #fff;
              background: #333;
              padding: 2px 6px;
              border-radius: 0 4px 0 4px;
            }

            .active-type {
              position: absolute;
              right: -38px;
              top: 3px;
              width: 100px;
              height: 18px;
              line-height: 18px;
              font-size: 12px;
              text-align: center;
              color: #fff;
              transform: rotate(45deg);
            }
          }
        }
      }
    }

    .return-info {
      color: #000;
      padding: 10px;
      border-radius: 6px;
      margin-bottom: 20px;
      position: relative;
      background: rgb(255, 247, 230);
      box-shadow: 0 0 6px 0 rgba(4, 51, 97, 0.29);

      .return-tips {
        position: absolute;
        right: 5px;
        top: 6px;
        font-size: 16px;
        color: red;
        font-weight: bold;
      }

      .return-title {
        font-size: 18px;
        margin-bottom: 10px;
      }

      .info-line {
        line-height: 22px;
        color: #666;

        .public-order {
          margin-left: 30px;
          border-radius: 4px;
          font-size: 12px;
          line-height: 16px;
          padding: 0 8px;
          color: rgb(250, 140, 22);
          background: rgb(255, 231, 186);
          border: 1px solid rgb(250, 140, 22);
        }
      }

      .old-money {
        color: #666;
        font-size: 15px;
        margin-top: 10px;
        padding-bottom: 8px;

        i {
          font-style: normal;
          color: red;
          font-size: 18px;
        }
      }

      .border-bottom {
        border-bottom: 1px solid #ddd;
      }

      .return-reason {
        p {
          margin-top: 10px;
          margin-bottom: 5px;
          font-size: 15px;
          color: #666;

          span {
            color: red;
          }
        }
      }
    }

    .return-btn {
      margin-top: 40px;
      width: 100%;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.table-style {
  border: 1px solid #eee;

  .change-img {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    color: #999;
    background: #eee;
    float: left;
  }

  .change-info {
    text-align: left;
    margin-left: 10px;
    height: 80px;

    p {
      font-size: 16px;
      color: #000;
    }
  }

  .color-size {
    border: 1px solid #eee;
    padding: 5px 0;
  }

  .red-text {
    color: red;
  }

  .change-goods {
    color: #fff;
    cursor: pointer;
    background: #0b83f3;
    padding: 5px 10px;
    border-radius: 2px;
  }

  .table-table {
    width: 70%;
    margin-left: 15%;
  }
}

.activity {
  background: linear-gradient(-90deg, #ec4620, #f08702, #f7d400) !important;
}

.gift {
  background: linear-gradient(0deg, #208aec, #02d7f0, #00c6f7) !important;
}

.add-buy {
  background: linear-gradient(
    0deg,
    rgb(116, 47, 169),
    rgb(101, 41, 147),
    rgb(72, 30, 105)
  ) !important;
}
</style>
