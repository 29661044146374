<template>
	<dialogModule
		top="15"
		width="750px"
		title="选择规格"
		v-if="chooseVisible"
		:dialogVisible="chooseVisible"
		@close="close">
		<span slot="close" class="el-icon-close close" @click="close"></span>
		<section
			slot="content"
			class="goods-box"
			v-loading="fetchLoading"
			element-loading-text="请稍后，全力加载中..."
			element-loading-spinner="el-icon-loading">
			<div>
				<div class="row align-center search-row">
					<input type="text" placeholder="请输入货号/简码" class="input" v-model="keyword" @keyup.enter="fetchList(1)">
					<el-button class="btn" type="primary" :loading="fetchLoading" @click="fetchList(1)">搜 索</el-button>
				</div>
				<div v-if="goodsList.length" class="goods-list">
					<div class="row space-between flex-wrap">
						<div class="row goods-card" v-for="(item, idx) in goodsList" :key="idx">
							<el-image lazy v-if="item.imageList && item.imageList[0]" class="goods-img" :src="item.imageList[0]" :preview-src-list="item.imageList"></el-image>
							<div v-else class="row align-center space-center goods-img">暂无图片</div>
							<div>
								<div class="goods-name">{{item.goodsName}}</div>
								<div class="goods-no">货号：{{item.goodsNo}}</div>
							</div>
							<div class="goods-add row align-center space-center" @click="showSizeInfo(item)">
								<i class="el-icon-plus"></i>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="goods-list">
					<noDataState margin-top="100"></noDataState>
				</div>
				<el-pagination
					background
					layout="total, sizes, prev, pager, next"
					:total="totalItems"
					:page-size="pageSize"
					:page-sizes="pageSizes"
					:current-page="currentPage"
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange">
				</el-pagination>
			</div>
			<AttrDialog v-if="attrVisible" :barCode="barCode" :maxNumber="maxNumber" :keyword="goodsNo" :isAllowNegativeStocks="isAllowNegativeStocks" @close="attrVisible = false" @sure="sureSend"></AttrDialog>
		</section>
	</dialogModule>
</template>

<script>
import AttrDialog from "./AttrDialog"
import noDataState from "@/components/noDataState"
import dialogModule from "@/components/dialogModule.vue"
import {activityQuerySpuGoods} from "@/libs/http/modules/posOrder";
export default {
	name: 'ActChange',
	props: {
		activityId: {
			type: String,
			default: () => ''
		},
		barCode: {
			type: String,
			default: () => ''
		},
		maxNumber: {
			type: Number,
			default: () => 0
		},
		isAllowNegativeStocks: {
			type: String,
			default: () => ''
		}
	},
	components: {
		AttrDialog,
		noDataState,
		dialogModule
	},
	data() {
		return {
			chooseVisible: true,
			fetchLoading: false,
			attrVisible: false,
			keyword: '',
			goodsNo: '',
			goodsList: [],
			currentPage: 1,
			pageSize: 10,
			totalItems: 0,
			pageSizes: [10, 20, 50],
		};
	},
	methods: {
		close() {
			this.chooseVisible = false;
			this.$emit("close");
		},
		sureSend(e){
			this.attrVisible = false
			this.close()
			this.$emit('confirm', e)
		},
		handleSizeChange(e){
			this.pageSize = e
			this.currentPage = 1
			this.fetchList(1)
		},
		handleCurrentChange(e){
			this.fetchList(e)
		},
		fetchList(type){
			if(!this.keyword.trim()){
				return this.$message({showClose: true, message: '请输入货号或简码查询', type: 'error', duration: 2000})
			}
			this.fetchLoading = true
			activityQuerySpuGoods({
				type: 'Y',
				page: type,
				keyword: this.keyword,
				pageSize: this.pageSize,
				activityId: this.activityId
			}).then(res=>{
				if(res){
					this.goodsList = res.items
					this.totalItems = Number(res.totalItem)
				}
				this.currentPage = type
				this.fetchLoading = false
			}).catch(error=>{
				this.fetchLoading = false
				this.$message({showClose: true, message: error, type: 'error', duration: 2000})
			})
		},
		showSizeInfo(item){
			this.attrVisible = true
			this.goodsNo = item.goodsNo
		}
	}
}
</script>

<style lang="scss" scoped>
.goods-box{
	padding: 15px;
	.search-row{
		.input{
			border: 1px solid #eee;
			outline: none;
			width: 100%;
			height: 40px;
			padding-left: 10px;
			line-height: 40px;
		}
		.btn{
			border-radius: 0;
		}
	}
	.goods-list{
		height: 470px;
		overflow: auto;
		margin-bottom: 15px;
		.goods-card{
			width: calc((100% - 20px) / 2);
			height: 82px;
			border: 1px solid #eee;
			margin-top: 10px;
			border-radius: 4px;
			position: relative;
			.goods-img{
				width: 80px;
				height: 80px;
				background: #eee;
				color: #999;
				font-size: 14px;
				margin-right: 10px;
				border-radius: 4px 0 0 4px;
			}
			.goods-name{
				font-size: 18px;
				color: #000;
				margin-top: 10px;
			}
			.goods-no{
				color: #999;
				font-size: 14px;
				margin-top: 15px;
			}
			.goods-add{
				position: absolute;
				right: 5px;
				bottom: 5px;
				width: 40px;
				height: 30px;
				line-height: 30px;
				cursor: pointer;
				background: rgb(198,231,255);
				i{
					font-size: 20px;
					font-weight: bold;
					color: rgb(85,192,235);
				}
			}
		}
	}
}
</style>