<template>
  <el-drawer
    v-if="salesVisiable"
    size="60%"
    :visible.sync="salesVisiable"
    v-loading="loading"
    element-loading-text="请稍后，全力加载中..."
    element-loading-spinner="el-icon-loading"
    @close="close">
    <span slot="title">促销单详情</span>
    <div v-if="activityInfo">
      <div class="activity-info">
        <div class="activity-icon"></div>
        <div class="activity-name">促销单信息</div>
      </div>
      <div class="row align-center flex-wrap activity-cell">
        <div><span>促销名称：</span>{{activityInfo.activityName}}</div>
        <div><span>促销类型：</span>{{activityInfo.type}}</div>
        <div><span>促销编号：</span>{{activityInfo.activityNo}}</div>
        <div><span>有效类型：</span>{{activityInfo.effectiveType === '0' ? '短期' : '长期'}}</div>
        <div><span>减扣金额：</span><span class="money-red">{{activityInfo.discountAmount}}</span></div>
        <div><span>实付金额：</span><span class="money-red">{{activityInfo.realAmount}}</span></div>
        <div><span>合计金额：</span><span class="money-red">{{activityInfo.totalAmount}}</span></div>
        <div><span>下单时间：</span>{{activityInfo.startTime}}</div>
      </div>
      <div class="line"></div>
      <div class="activity-info">
        <div class="activity-icon"></div>
        <div class="activity-name">促销单货品列表</div>
      </div>
      <div style="margin: 20px;">
        <el-table
          :data="activityInfo.participationActivityGoods"
          :header-cell-style="{background: '#FAFAFA',color: '#436481'}"
          :cell-style="{padding: '5px'}">
          <el-table-column prop="imageList" label="货品图片" align="center">
            <div slot-scope="scope" class="row align-center space-center">
              <div v-if="!scope.row.imageList"  class="row align-center space-center" style="width: 60px;height: 60px;border-radius: 4px;background: #F5F5F5;font-size: 12px;color: #999;">暂无图片</div>
              <el-image v-else style="width: 60px;height: 60px;border-radius: 4px;" :src="scope.row.imageList ? scope.row.imageList[0] : ''" :preview-src-list="scope.row.imageList"></el-image>
            </div>
          </el-table-column>
          <el-table-column prop="goodsName" label="货品名称" align="center"></el-table-column>
          <el-table-column prop="barCode" label="条码" align="center"></el-table-column>
          <el-table-column prop="goodsNo" label="货号" align="center"></el-table-column>
          <el-table-column prop="retailPrice" label="价格" align="center"></el-table-column>
          <el-table-column prop="attrList" label="颜色 / 尺码" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.attrList" class="row space-center align-center text-center" style="border:1px solid #DEEEF9;background:#fff;color:#333;line-height:26px;border-radius:2px;cursor:pointer;width:150px;">
                <p v-for="(item, index) in scope.row.attrList.slice(0,2)" :key="item.id">{{item.value}}
                  <span v-if="index === 0" style="margin:0 3px;">,</span>
                </p>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import {promotionActivity} from "@/libs/http/modules/posOrder.js";
export default {
  props:['promotionObj'],
  data() {
    return {
      salesVisiable: true,
      activityInfo: null,
      loading: false,
    };
  },
  created() {
    this.fetchList()
  },
  methods: {
    close() {
      this.salesVisiable = false;
      this.$emit("close");
    },
    fetchList(){
      this.loading = true;
      promotionActivity({
        activityId: this.promotionObj.id,
        orderId: this.promotionObj.orderId
      }).then(data => {
        this.activityInfo = data;
        this.loading = false;
      }).catch(err => {
        this.loading = false;
        this.$message({ showClose: true, message: err, type: "error", duration: 2000});
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-info{
  margin-left: 20px;
  position: relative;
  .activity-icon{
    width: 4px;
    height: 24px;
    background: #067CF2;
    position: absolute;
    left: -20px;top: 0;
  }
  .activity-name{
    font-size: 18px;
    font-weight: 500;
    color: #000;
  }
}
.activity-cell{
  div{
    width: calc(100% / 3);
    margin-top: 20px;
    span{
      margin-left: 20px;
      color: #999;
    }
    .money-red{
      color: #F8475F;
    }
  }
}
.line{
  width: 100%;
  height: 10px;
  background: #f5f5f5;
  margin: 20px 0;
}
</style>